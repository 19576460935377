import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UIService } from './ui.service';
import { SESSION_TIME } from './../_config/config';
import { JwtHelperService } from "@auth0/angular-jwt";
import { map} from 'rxjs/operators';
import { API_ENDPOINT } from './../_config/config';
import { User } from 'src/types/User';
import { AuthService } from './auth.service';
import { Project } from 'src/types/Project';
import { PartnersService } from './partners.service';
import { Role } from '../_models/role';
import { CustomerService } from './customer.service';

@Injectable({
    providedIn: 'root'
  })
export class UserService {
    token: string;
    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private customerService: CustomerService,
        private partnerService: PartnersService,
    ) { }

    /** Get all users for admin view */
    getUsers(limit: number, pageNumber: number = 1, sortAttr: string = 'email', sortDirection: 'ASC' | 'DESC' = 'ASC', nameSearch?: string) {
        const offset = limit * (pageNumber - 1);

        let params = new HttpParams()
            .set('limit', limit.toString())
            .set('offset', offset.toString())
            .set('sortAttr', sortAttr)
            .set('sortDirection', sortDirection);
        params = nameSearch ? params.append('email', nameSearch) : params;

        if(this.authService.isSuperadmin)    return this.getAllUsers(params);
        if(this.authService.isPartnerAdmin)  return this.getUsersByPartner(params);
        if(this.authService.isCustomerAdmin) return this.getUsersByCustomer(params);
    }

    /** Get all users in the database */
    private getAllUsers(params: HttpParams) {
        return this.http.get<{ users: User[], count: number }>(`${API_ENDPOINT}/users/all`, { params });
    }

    private getUsersByCustomer(params: HttpParams) {
        const customerId = this.customerService.customer.id;
        return this.http.get<{ users: User[], count: number }>(`${API_ENDPOINT}/customers/${customerId}/users`, { params });
    }

    /** Get all users that reside under the current partner */
    private getUsersByPartner(params: HttpParams) {
        const partnerId = this.partnerService.partner.id;
        return this.http.get<{ users: User[], count: number }>(`${API_ENDPOINT}/partners/${partnerId}/users`, { params });
    }

    /** create user in API and Database */
    create(user: User) {
        // read only user
        if(user.role === Role.ReadOnly) {
            return this.http.post<{ user: User }>(`${API_ENDPOINT}/users`, { ...user });
        }

        // partner admin
        if(user.role === Role.PartnerAdmin) {
            return this.http.post<{ admin: User }>(`${API_ENDPOINT}/partners/${user.partner}/admins`, { ...user }).pipe(
                map(resp => {
                    return { user: resp.admin };
                })
            )
        }

        // customer admin
        if(user.role === Role.CustomerAdmin) {
            return this.http.post<{ admin: User }>(`${API_ENDPOINT}/customers/${user.customer}/admins`, { ...user }).pipe(
                map(resp => {
                    return { user: resp.admin };
                })
            )
        }
    }

    /** Update user in API/Database */
    update(userId: string, user: User) {
        const body: any = {
            id: userId,
            partner: user.partner,
            email: user.email,
            customer: user.customer,
            role: user.role,
            status: user.status,
            projectIds: user.projects,
        };

        return this.http.patch<{ user: User }>(`${API_ENDPOINT}/users`, body);
    }
 
    /** Send delete user request to API */
    delete(userId: string) {
        return this.http.delete<{ message: string }>(`${API_ENDPOINT}/users/${userId}`);
    }

    /** Get user's assigned projects */
    getAssignedProjects() {
        const userId = this.authService.userId;
        return this.http.get<{ projects: Project[] }>(`${API_ENDPOINT}/projects?userId=${userId}`)
    }

    /** Assign user to project(s) */
    assignProjects(projectIds: string[], userId: string) {
        return this.http.patch<{ message: string }>(`${API_ENDPOINT}/projects/assign`, { projectIds, userId });
    }

}