import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {CustomerService } from '../../_services/customer.service';
import { AlertService, AuthService, UIService } from 'src/app/_services';
import { COUNTRY_LIST } from 'src/app/_utils/countries';
import { isPhoneNumberValidator } from 'src/app/_validators/isPhoneNumber.validator';
import { CountService } from 'src/app/_services/count.service';
import { PartnersService } from 'src/app/_services/partners.service';
import { PartnerPublicInfo } from 'src/types/Partner';
import { Role } from 'src/app/_models/role';

@Component({
  selector: 'app-customerform',
  templateUrl: './customerform.component.html',
  styleUrls: ['./customerform.component.scss']
})
export class CustomerformComponent implements OnInit {
  view: string;
  customerForm: FormGroup;
  submitted = false;
  formData = {};
  loading: boolean;
  customerId: string;
  showReturnButton: boolean = false;

  partners: PartnerPublicInfo[];
  countries: string[] = COUNTRY_LIST;

  Role = Role;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private partnerService: PartnersService,
    private customerService: CustomerService,
    private countService: CountService,
    private location: Location,
    private alert: AlertService,
    private uiservice : UIService
  ) { }

  ngOnInit() {
    // if passed view mode from previous route, set to that value, otherwise 'new'
    this.view = history.state.data ? history.state.data.view : 'new';
    let pageTitle = this.view === 'edit' ? 'Edit Customer' : 'Create Customer';
    this.uiservice.setTitle(pageTitle);

    // if logged in user is superadmin, allow partner input
    // otherwise, only allow current client partner to be assigned
    this.partners = this.partnerService.partnerList;
    if(!this.partners) {
      this.getPartnerList();
    }
    const initialPartnerValue = this.authService.isSuperadmin ?
      '' : { value: this.partnerService.partner.id, disabled: true };
    
    // build form group
    this.customerForm = this.formBuilder.group({
      partner: [initialPartnerValue, Validators.required],
      name: ['', Validators.required],
      status: ['', Validators.required],
      phone: ['', isPhoneNumberValidator],
      email: ['', [Validators.required, Validators.email]],
      address: [''],
      country: ['', Validators.required],
    });

    // if in edit mode, set existing form values
    if(this.view === 'edit') {
      const customer = history.state.data.customer;
      console.log(customer);
      this.customerId = customer.id;

      this.customerForm.setValue({
        partner: customer.partner.id,
        name: customer.name,
        status: customer.status,
        email: customer.email || '',
        phone: customer.phone || '',
        address: customer.address || '',
        country: customer.country || '',
      });
    }

  }

  /** Go back to last page */
  navigateBack() {
    this.location.back();
  }

  /** Get partner list */
  getPartnerList() {
    this.partnerService.getAllPartnersPublicInfo().subscribe(({ partners }) => {
      this.partners = partners;
    })
  }
  
  onSubmit() {
    this.submitted = true;

    // stop the process here if form is invalid
    if (this.customerForm.invalid) {
      alert('Form is invalid. Please fix highlighted errors');
      // console.log(this.customerForm.errors)
      return false;
    }

    this.loading = true;
    this.customerId ? this.updateCustomer() : this.createCustomer();
  }
  createCustomer(){
    // format submission data
    const submissionData = this.formatSubmissionData(this.customerForm.getRawValue());
    this.customerService.create(submissionData).subscribe(
      ({ customer }) => {
        this.loading = false;
        this.submitted = false;
        this.customerForm.reset();
        this.alert.success('Customer created successfuly!');
        this.showReturnButton = true;

        // add 1 to customers count
        const currentCounts = this.countService.counts.value;
        this.countService.counts.next({
          ...currentCounts,
          customers: currentCounts.customers + 1,
        })
      },
      (error) => { 
        this.loading = false;
        this.submitted = false;
        error.statusCode && error.statusCode === 400 ?
          this.alert.error(error.message) :
          this.alert.error('Something went wrong!');
      }
    );
  }

  /** Update customer with new form values */
  updateCustomer() {
    // format submission data
    const submissionData = this.formatSubmissionData(this.customerForm.getRawValue());
    submissionData.id = this.customerId;

    this.customerService.update(submissionData).subscribe(
      () => {
        this.submitted = false;
        this.customerForm.reset();
        this.loading = false;
        this.showReturnButton = true;
        this.alert.success('Customer updated successfuly!');
      },
      () => { 
        this.loading = false;
        this.submitted = false;
        this.alert.error('Something went wrong!');
      });
  }

  resetFrom(){
    setTimeout(() => {
      this.customerForm.markAsUntouched();
      Object.keys(this.customerForm.controls).forEach((name) => {
        let control = this.customerForm.controls[name];
        control.reset();
        control.setErrors(null);
      });
    }, 100);
  }
  tranpileToQuery(obj:any){
    var str = "";
    for (var key in obj) {
        if (str != "") {
            str += "&";
        }
        str += key + "=" + obj[key];
    }

    return str;
  }

  formatSubmissionData(formData: any) {
    Object.keys(formData).forEach(key => {
      if(!formData[key]) delete formData[key];
    });

    return formData;
  }
}
