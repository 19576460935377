import { Pipe, PipeTransform } from '@angular/core';
import { formatBytes } from '../_utils/utils';

@Pipe({
  name: 'formatBytes'
})
export class FormatBytesPipe implements PipeTransform {

  transform(value: number, ...args: any[]): string {
    return formatBytes(value);
  }

}
