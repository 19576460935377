// PACKAGES
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FileExplorerStateService } from 'src/app/_state/file-explorer/file-explorer-state.service';
// APP
import { InvData } from '../../dashboard/file-explorer-page/file-explorer-page.types';

@Component({
  selector: 'app-breadcrumb-panel',
  templateUrl: './breadcrumb-panel.component.html',
  styleUrls: ['./breadcrumb-panel.component.scss']
})
export class BreadcrumbPanelComponent implements OnInit {
  @Input() activeDirectory: InvData;
  @Output() activeDirectoryChange = new EventEmitter();

  current_path: InvData[];
  _fileExplorerStateListener: Subscription;

  constructor(private fileExplorerStateService: FileExplorerStateService) { }

  ngOnInit() {
    this._fileExplorerStateListener = this.fileExplorerStateService.explorerState.subscribe(({ activeDirectory }) => {
      if(!activeDirectory) {
        this.current_path = [];
        return;
      }

      this.current_path = [];
      let curr_node: InvData = activeDirectory;

      while(curr_node) {
        this.current_path.push(curr_node);
        curr_node = curr_node.parent;
      }

      this.current_path.reverse();
    });
  }

  /** Set active directory on breadcrumb click */
  setActiveDirectory(directory: InvData) {
    this.fileExplorerStateService.setActiveDirectory(directory);
  }

}
