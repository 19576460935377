import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { CustomerService, AlertService, UIService, AuthService } from 'src/app/_services/index';
import { ConfirmationDialogService } from 'src/app/_services/cdialog.service';
import { PageConfig } from './../../_config/config';
import { CountService } from 'src/app/_services/count.service';
import { Customer } from '../../../types/Customer';

enum CustomerSortMethod { CUSTOMER_NAME = 'name', PARTNER = 'partner', EMAIL = 'email', COUNTRY = 'country' };
type SortDirection = 'ASC' | 'DESC';

@Component({
  selector: 'app-customerlist',
  templateUrl: './customerlist.component.html',
  styleUrls: ['./customerlist.component.scss']
})
export class CustomerlistComponent implements OnInit {
  
  constructor(
    private customerService:CustomerService,
    private formBuilder: FormBuilder,
    public auth: AuthService,
    private alert:AlertService,
    private uiservice : UIService,
    private countService: CountService,
    private confirmationDialogService : ConfirmationDialogService
  ) { }

  customerSearchForm: FormGroup;
  recordLimitForm: FormGroup;
  submitted = false;
  customers: any[];
  loading : any;
  msg : any;
  filter:boolean;

  // sorting
  sortMethod: CustomerSortMethod = CustomerSortMethod.CUSTOMER_NAME;
  sortDirection: SortDirection = 'ASC';
  public sortMethodTypes = CustomerSortMethod;

  // pagination declaration
  pagination : Record<string, any>;
  sortKeyArray = [{ id: '', sortkey: '' }];
  currentPage : number;

  ngOnInit() {
    // pagination configuration
    this.pagination = {};
    this.pagination['options'] = PageConfig.limitOptions;
    this.pagination['limit'] = PageConfig.limit;
    this.pagination['show'] = PageConfig.enabled;
    this.currentPage = PageConfig.startPage;

    this.customerSearchForm = this.formBuilder.group({
      customerName: ['']
    });
    this.recordLimitForm = this.formBuilder.group({
      recordlimit: [PageConfig.limitOptions[0].value]
    });

    this.uiservice.setTitle('List Customer'); 
    this.getCustomers();

    // after user has not typed for 500 ms in customer name search, run search again
    this.customerSearchForm.valueChanges.pipe(debounceTime(500)).subscribe(({ customerName: name }) => {
      this.getCustomers();
    });
  }
  
  /** Generate Pagination navigator */
  pageConfig(totalCount: number) {
    this.pagination['total'] = totalCount;
    this.pagination['pages'] = this.uiservice.roundUp(totalCount/this.pagination['limit']);
    
    // only show pagination if total count > current limit
    this.pagination.show = this.pagination.total > this.pagination.limit ? true : false;
  }
  
  /** go to previous or next page and get customers with new offset */
  paginate(mode: 'forward' | 'previous'){
    if((this.currentPage === this.pagination['pages'] && mode === 'forward') || this.currentPage === 0 && mode === 'previous'){
      return false;
    }

    mode === 'forward' ? this.currentPage += 1 : this.currentPage -= 1;
    this.getCustomers();
  }

  /** Get customers from database */
  getCustomers() {
    this.loading = true;

    this.customerService.getCustomers(
      this.pagination.limit, 
      this.currentPage,
      this.sortMethod,
      this.sortDirection,
      this.customerSearchForm.value.customerName || undefined,
    ).subscribe(
      ({ customers, count }) => {
        this.customers = customers;
        this.msg = count === 0 ? 'No customer found' : undefined;

        this.pageConfig(count);
        this.loading = false;
      },
      ({ error }) => {
        this.loading = false;
        this.msg = "Something went wrong";
      }
    )
  }
  
  /** Delete customer */
  deleteCustomer(customer: Customer) { 
    // confirm user wants to delete customer
    this.confirmationDialogService.confirm('Confirmation', `Do you really want to delete customer "${customer.name}"?`)
      .then(confirmed => {
        if(confirmed) {
          // delete customer
          this.customerService.delete(customer.id).subscribe(
            () => {
              this.alert.success('Customer deletion queued successfully');
              this.getCustomers();
              
              // subtract 1 from customers count
              const currentCounts = this.countService.counts.getValue();
              this.countService.counts.next({
                ...currentCounts,
                customers: currentCounts.customers - 1,
              });
            },
            ({ error }) => {
              console.error(error);
              this.alert.error('Something went wrong!');
            }
          )
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  updateRecords(limit:number){
    this.currentPage = 0;
    this.pagination['limit'] = limit;
    this.sortKeyArray = [{ id: '', sortkey: '' }];
    this.getCustomers();
  }
  
  sort(property: CustomerSortMethod) {
    // if property is the same as current, switch sort direction
    if(this.sortMethod === property) {
      this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.sortMethod = property;
      this.sortDirection = 'ASC';
    }

    return this.getCustomers()
  };
}
