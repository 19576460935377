import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';

import { AlertService, AuthService, CustomerService } from './../_services/index';
import { UIService } from '../_services/ui.service';
import { PartnersService } from '../_services/partners.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
    model: any = { username: '', password: '' };
    loading = false;
    returnUrl: string = '/dashboard';
    fieldType : string = 'password';
    alertMessage: string;

    logo: string;
    logoListener: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private partnerService: PartnersService,
        private customerService: CustomerService,
        private authService: AuthService,
        private alertService: AlertService,
        private uiservice:UIService
    ) {
        // look for message passed after idle timeout
        const navigation = this.router.getCurrentNavigation();
        const message = navigation.extras.state ? navigation.extras.state.message : undefined;
        this.alertMessage = message;
    }

    async ngOnInit() {
        this.checkLoggedIn();
        this.uiservice.setTitle('Login');
        this.logoListener = this.uiservice.logoSubject.subscribe(logo => this.logo = logo);

        const parsedUrl = new URL(window.location.href);
        const subdomainArr = parsedUrl.host.split('.');

        // if user did not visit through partner's subdomain, reroute to 404
        // while hosted arr length will be 4, in localhost arr length will be 2
        if(subdomainArr.length !== 2 && subdomainArr.length !== 4) {
            this.router.navigate(['/404']);
        }
        else {
            try {
                const partnerUri = subdomainArr[0];
                const { partner } = await this.partnerService.getPartnerByUri(partnerUri).toPromise();
                this.partnerService.partner = partner;
                // wait 1 second
                (async (ms: number) => new Promise(resolve => setTimeout(resolve, ms)))(1000);
            } catch(err) {
                this.router.navigate(['/404']);
            }
        }
    }

    ngAfterViewInit() {
        // simulate body click for windows-chrome autofill issue
        document.body.click();
        // if idle timeout message was passed in, throw alert
        setTimeout(() => { if(this.alertMessage) { alert(this.alertMessage) }}, 500);
    }

    ngOnDestroy() {
        this.logoListener.unsubscribe();
    }

    mouseDown() {
        this.fieldType = 'text';
    }

    mouseUp() {
        this.fieldType = 'password';
    }

    login() {
        this.loading = true;
        this.authService.login(this.model.username, this.model.password).pipe(
            switchMap(({ access_token }) => {
                this.authService.setAccessToken(access_token);
                
                // if user has Customer Admin role, fetch current customer attributes
                if(this.authService.isCustomerAdmin) return this.customerService.getCurrentCustomer();

                return of({ access_token });
            }),
        ).subscribe(
            () => {
                console.log(this.customerService.customer);
                this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
                this.router.navigate(['/dashboard']);
            },
            (error) => {
                this.loading = false;
                error.status === 401 ?
                    this.alertService.error('Invalid Credentials') :
                    this.alertService.error('Internal Server Error. Please try again later.');
            }
        )
    }

    checkLoggedIn() {
        if(this.authService.loggedIn){
            let redirectURL = this.returnUrl || '/dashboard'
            this.router.navigate([redirectURL]);
        }
    }
    
}
