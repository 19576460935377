// PACKAGES
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid'
// APP
import { ActiveProjectState } from 'src/app/_state/projects/projects.reducer';
import { Project } from 'src/types/Project';
import { FileExplorerService } from 'src/app/_services/file-explorer.service';
import { InvData, BackupSessionInfo, MediaInfo, TypeDirectoryInfo } from './file-explorer-page.types';
import { FileExplorerStateService } from 'src/app/_state/file-explorer/file-explorer-state.service';
import { toTitleCase } from 'src/app/_utils/utils';

@Component({
  selector: 'app-file-explorer-page',
  templateUrl: './file-explorer-page.component.html',
  styleUrls: ['./file-explorer-page.component.scss'],
})
export class FileExplorerPageComponent implements OnInit, OnDestroy {
  activeProject: Project;
  activeDirectory: InvData;
  loading: boolean = false;

  _fileExplorerStateListener: Subscription;
  _activeProjectStateListener: Subscription;

  constructor(
    private router: Router,
    private fileExplorerService: FileExplorerService,
    private fileExplorerStateService: FileExplorerStateService,
    private store: Store<{ activeProject: ActiveProjectState }>
  ) { }

  ngOnInit() {
    this._activeProjectStateListener = this.store.select('activeProject').pipe(
      switchMap(data => this.handleActiveProjectState(data))
    ).subscribe(data => this.handleGetProjectMedia(data));
  }

  /** Get active project state and, if necessary, check backup data state */
  private handleActiveProjectState({ activeProject }: { activeProject: Project }) {
    this.activeProject = activeProject;

    // if project is null (refreshed or selected no project), navigate back to dashbaord
    if(this.activeProject === null) { 
      this.router.navigate(['/dashboard']);
    }

    // if file explorer state active project has not changed, do nothing
    const currFileExplorerStateProject = this.fileExplorerStateService.getCurrentState().activeProject;
    if(currFileExplorerStateProject === this.activeProject) {
      return of('DO NOTHING');
    }

    // fetch project media
    this.loading = true;
    return this.fileExplorerService.getProjectMedia(this.activeProject.id);
  }

  /** Handle project media data and map to backup data */
  private handleGetProjectMedia(data: 
    { 
      count: { media: number, backupSessions: number },
      media: MediaInfo[],
      backupSessions: BackupSessionInfo[] 
    } | string
  ) {
    if(typeof data === 'string') {
      return;
    }

    if(!data || data === null) { 
      this.fileExplorerStateService.setActiveProject(this.activeProject, []);
      this.loading = false;
      return;
    };

    const directories = this.sortByMediaType(data);

    // map media to backup variable to pass to file-explorer state
    this.fileExplorerStateService.setActiveProject(this.activeProject, directories);
    this.loading = false;

    // open 'Media Sources' row by default
    this.fileExplorerStateService.setActiveDirectory(directories[0]);
    this.fileExplorerStateService.toggleFolderExpansion(directories[0].details.id);
  }

  /** map media and backup sessions into tree format */
  sortByMediaType(data: any) {
    const media = data.media.map(mediaRecord => new InvData(mediaRecord)).sort(this.sortMedia);
    media.forEach(mediaDirectory => {
      const contents = data.backupSessions
        .filter(backupRecord => backupRecord.mediaId === mediaDirectory.details.id)
        .map(backupRecord => new InvData(backupRecord, mediaDirectory));

      mediaDirectory.contentsFetched = true;
      mediaDirectory.setContents(contents);
    })

    const typeDirectories = media
      .map((val: any) => val.details.mediaType)
      .filter((val, ind, self) => self.indexOf(val) === ind)
      .map(mediaType => new InvData({
        id: uuidv4(),
        name: mediaType ? toTitleCase(`${mediaType}s`) : 'Others',
        mediaType: mediaType,
        type: 'PARENT' 
      }));

    typeDirectories.forEach(typeDir => {
      typeDir.contentsFetched = true;
      typeDir.setContents(media.filter((m: any) => m.details.mediaType === typeDir.details.mediaType))
    });
    
    const topLevel = new InvData({
      id: uuidv4(),
      name: toTitleCase('Media Sources'),
      mediaType: 'SOURCES',
      type: 'PARENT'
    });

    topLevel.contentsFetched = true;
    topLevel.setContents(typeDirectories);

    return [topLevel];
  }

  sortMedia(a: InvData, b: InvData) {
    const aDetails = <MediaInfo>a.details;
    const bDetails = <MediaInfo>b.details;

    if(aDetails.name > bDetails.name) return 1;
    if(aDetails.name < bDetails.name) return -1;
    return 0;
  }

  ngOnDestroy() {
    this._activeProjectStateListener.unsubscribe();
  }

}
