import { Component, OnInit } from '@angular/core';
import { UserService } from '../../_services/user.service';
import { UIService, AlertService, AuthService } from 'src/app/_services';
import { DialogService } from 'src/app/_services/dialog.service';
import { ConfirmationDialogService } from 'src/app/_services/cdialog.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageConfig } from './../../_config/config';
import { CountUpdateService} from '../../_services/count-update.service';
import { debounceTime } from 'rxjs/operators';
import { User } from 'src/types/User';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CountService } from 'src/app/_services/count.service';

enum UserSortMethod { EMAIL = 'email', CUSTOMER_NAME = 'customer', PARTNER_NAME='partner', ROLE = 'role' };
type SortDirection = 'ASC' | 'DESC';

@Component({
  selector: 'user-list',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})
export class UserlistComponent implements OnInit {
  users : any;
   // array of all items to be paged
  submitted = false;
  allItems: any;
  loading:any;
  msg:any;
  assignedProjects:any;
  currentId : string;
  currentUserId : string;
  currentUser : string;
  
  filter:boolean;
  
  // sorting
  sortMethod: UserSortMethod = UserSortMethod.EMAIL;
  sortDirection: SortDirection = 'ASC';
  public sortMethodTypes = UserSortMethod;

  // pagination declaration
  pagination : Record<string, any>;
  sortKeyArray = [{ id: '', sortkey: '' }];
  currentPage : number;

  constructor(
    private confirmationDialogService: ConfirmationDialogService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    public auth: AuthService,
    private uiservice : UIService,
    private alert : AlertService,
    private countService: CountService,
  ) { }

  userSearchForm: FormGroup;
  recordLimitForm: FormGroup;
  ngOnInit() {
    // pagination configuration
    this.pagination = {};
    this.pagination['options'] = PageConfig.limitOptions;
    this.pagination['limit'] = PageConfig.limit;
    this.pagination['show'] = PageConfig.enabled;
    this.currentPage = PageConfig.startPage;

    this.userSearchForm = this.formBuilder.group({
      email: ['']
    });
    
    this.recordLimitForm = this.formBuilder.group({
      recordlimit: [PageConfig.limitOptions[0].value]
    });

    this.uiservice.setTitle('List User'); 
    this.getUsers();

    // after user has not typed for 500 ms in username search, run search again
    this.userSearchForm.valueChanges.pipe(debounceTime(500)).subscribe(({ email }) => {
      this.getUsers();
    });
  }
  
  /** Generate Pagination navigator */
  pageConfig(totalCount: number) {
    this.pagination['total'] = totalCount;
    this.pagination['pages'] = this.uiservice.roundUp(totalCount/this.pagination['limit']);
    
    // only show pagination if total count > current limit
    this.pagination.show = this.pagination.total > this.pagination.limit ? true : false;
  }
  
  /** go to previous or next page and get customers with new offset */
  paginate(mode: 'forward' | 'previous'){
    if((this.currentPage === this.pagination['pages'] && mode === 'forward') || this.currentPage === 0 && mode === 'previous'){
      return false;
    }

    mode === 'forward' ? this.currentPage += 1 : this.currentPage -= 1;
    this.getUsers();
  }

  /** Get users */
  getUsers() {
    this.loading = true;

    this.userService.getUsers(
      this.pagination.limit, 
      this.currentPage,
      this.sortMethod,
      this.sortDirection,
      this.userSearchForm.value.email || undefined,
    ).subscribe(
      ({ users, count }) => {
        this.users = users;
        this.msg = count === 0 ? 'No users found' : undefined;

        this.pageConfig(count);
        this.loading = false;
      },
      ({ error }) => {
        this.loading = false;
        this.msg = "Something went wrong";
      }
    )
  }

  /** Delete user */
  deleteUser(user: User) { 
    // confirm user wants to delete user
    this.confirmationDialogService.confirm('Confirmation', `Do you really want to delete user "${user.email}"?`)
      .then(confirmed => {
        if(confirmed) {
          // delete user
          this.userService.delete(user.id).subscribe(
            () => {
              this.alert.success('User deletion queued successfully');
              this.getUsers();

              // subtract 1 from users count
              const currentCounts = this.countService.counts.getValue();
              this.countService.counts.next({
                ...currentCounts,
                users: currentCounts.users - 1,
              });
            },
            ({ error }) => {
              console.error(error);
              this.alert.error('Something went wrong!');
            }
          )
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
  
  updateRecords(limit:number){
    this.currentPage = 0;
    this.pagination['limit'] = limit;
    this.getUsers();
  }
  
  sort(property: UserSortMethod) {
    // if property is the same as current, switch sort direction
    if(this.sortMethod === property) {
      this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.sortMethod = property;
      this.sortDirection = 'ASC';
    }

    return this.getUsers()
  };

  /** Toggle assign projects popover open and close */
  togglePopover(popover: NgbPopover, user: User) {
    popover.isOpen() ? popover.close() : popover.open({ user });
  }
}
