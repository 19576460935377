import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AlertService, UIService } from 'src/app/_services';
import { ConfirmationDialogService } from 'src/app/_services/cdialog.service';
import { PartnersService } from 'src/app/_services/partners.service';
import { Partner } from 'src/types/Partner';

@Component({
  selector: 'app-partner-view',
  templateUrl: './partner-view.component.html',
  styleUrls: ['./partner-view.component.scss']
})
export class PartnerViewComponent implements OnInit {
  partner: Partner;
  loading: boolean = false;
  deleted: boolean = false;

  constructor(
    private partnerService: PartnersService,
    private confirmationService: ConfirmationDialogService,
    private alertService: AlertService,
    private location: Location,
    private UIService: UIService,
  ) { }

  ngOnInit() {
    this.partner = history.state.data.partner;
    this.UIService.setTitle('View Partner');
  }

  navigateBack() {
    this.location.back();
  }

  deletePartner() {
    this.confirmationService.confirm('Confirmation', `Do you really want to delete partner "${this.partner.name}"? This action is permanent.`)
    .then(confirmed => {
      if(confirmed) {
        // delete partner
        this.loading = true;
        this.partnerService.deletePartner(this.partner.id).subscribe(
          () => {
            this.alertService.success('Partner deletion queued successfully');
            this.loading = false;
            this.deleted = true;
          },
          ({ error }) => {
            this.loading = false;
            error.statusCode && error.statusCode === 400 ?
              this.alertService.error(error.message) :
              this.alertService.error("Something went wrong!");
          }
        )
      }
    }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

}
