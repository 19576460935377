// VENDOR
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgbCalendar, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
// APP
import { SearchService, SearchOptions, FileQueryReturnObject } from 'src/app/_services/search.service';
import { AuthService, AlertService } from 'src/app/_services';
import { PageConfig } from './../../_config/config';
import { DialogService } from 'src/app/_services/dialog.service';
import { Project } from 'src/types/Project';
import { UIService } from 'src/app/_services/ui.service';
import { ActiveProjectState } from 'src/app/_state/projects/projects.reducer';
import { TimerService } from 'src/app/_services/timer.service';
import { ExcelService } from 'src/app/_services/excel.service';
import { switchMap } from 'rxjs/operators';

enum FileSortMethod { 
  MEDIA = 'media',
  EXTENSION = 'extension',
  FILENAME = 'name',
  SIZE = 'size',
  PATH = 'path',
  CREATED_DATE = 'createDate',
  MODIFY_DATE = 'modifyDate',
  ACCESS_DATE = 'accessDate',
  HASH = 'hash'
};

type SortDirection = 'ASC' | 'DESC';

enum formFieldAliases {
  fileName = "File Name",
  mediaName = "Media Name",
  minSize = "Minumum Size (bytes)",
  maxSize = "Maximum Size (bytes)",
  hash = "Hash",
  filePath = "Path",
  extension = "File Extension",
  maxAccessDate = "Newest Access Date",
  minAccessDate = "Oldest Access Date",
  maxCreateDate = "Newest Created Date",
  minCreateDate = "Oldest Created Date",
  maxModifyDate = "Newest Modify Date",
  minModifyDate = "Oldest Modify Date"
}

@Component({
  selector: 'app-advancesearch',
  templateUrl: './advancesearch.component.html',
  styleUrls: ['./advancesearch.component.scss']
})
export class AdvancesearchComponent implements OnInit {
  files: any[];
  /** Form field aliases for better ui display */
  aliases = formFieldAliases;
  /** object of key-value pairs for active search filters */
  activeSearchFilters: { [key: string]: string } = {};

  minDate: NgbDateStruct = { year: 1970, month: 1, day: 1 };
  maxDate: NgbDateStruct = { year: 2030, month: 12, day: 31 };

  activeProject: Project;
  _activeProjectStateListener: Subscription;

  // sorting
  sortMethod: FileSortMethod;
  sortDirection: SortDirection = 'ASC';
  public sortMethodTypes = FileSortMethod;

  // should search dropdown be open on load
  dropdownOpen: boolean = true;

  math = Math;
  oad: any;
  nad: any;
  showAdfrom: boolean;
  iconClass: string;
  
  totalRecords: number;
  fileAnalytics: FileQueryReturnObject['analytics'];

  loading: any;
  formLoading: any;
  searchApplied: any = false;
  msg: any;
  pid: any;
  cid: any;

  searchForm: FormGroup;
  taggedSearchForm: FormGroup;

  recordLimitForm: FormGroup;
  submitted = false;
  todayNumber: number = Date.now();
  todayDate: Date = new Date();
  todayString: string = new Date().toDateString();
  todayISOString: string = new Date().toISOString();
  
  //  pagination declaration∂
  public PAGINATION_RECORD_LIMIT = 10000 // 10,000
  pagination: Record<string, any>;
  currentPage: number;
  changingPage: boolean = false;
  
  // Tagging 
  tagForm: FormGroup;
  tagFormSubmitted: boolean = false;
  unTagFormSubmitted: boolean = false;
  allSelected: boolean = false;
  allRecords: boolean = false;
  taggedOnly: boolean = false;
  checkedRecords = [];
  downloadStarted: boolean = false;
  cursor: '';

  getFilesSubscription: Subscription;

  constructor(
    private router: Router,
    private uiservice: UIService,
    private calendar: NgbCalendar,
    private searchService: SearchService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alert: AlertService,
    private dialog: DialogService,
    private timerService: TimerService,
    private excel: ExcelService,
    private store: Store<{ activeProject: ActiveProjectState }>,
  ) {
      // check to see if coming from "View Tagged Items" link from File Explorer Page
      const navState = router.getCurrentNavigation().extras.state;
      this.taggedOnly = navState ? navState.tagged : false;

      // dropdown should not start open if navigating from anywhere but top nav
      this.dropdownOpen = navState && navState.tagged ? false : true;

      route.params.subscribe(val => {
        this.ngOnInit();
      });

      this.dialog.reloadComponent.subscribe(val=>{
        this.cid = val.id;
        this.pid = val.projectId;
        this.allRecords = false;
        this.allSelected = false;
        this.checkedRecords = [];
        sessionStorage.removeItem('allRecords');
        this.ngOnInit();
      })
    }

  selectToday() {
    this.oad = this.calendar.getToday();
    this.nad = this.calendar.getToday();
  }

  ngOnInit() {
    // initialize forms for this page
    this.buildForms();

    this.pagination = {};
    this.pagination['limit'] = PageConfig.searchLimitOptions[0].value;
    this.pagination['lastpage'] = false;
    this.pagination['show'] = false;
    this.pagination['options'] = PageConfig.searchLimitOptions;
    this.currentPage = PageConfig.startPage;

    // subscribe to changes in ngrx store
    this._activeProjectStateListener = this.store.select('activeProject').subscribe(({ activeProject }) => {

      if(activeProject === null) { // if active project not defined (user reloaded page), navigate to dashboard page
        this.router.navigate(['/dashboard']);
      } 
      else if(this.activeProject !== activeProject) {
        this.activeProject = activeProject;
        this.cid = activeProject.customer.id;
        this.pid = activeProject.id;
        this.activeSearchFilters = {};
        this.searchForm.reset('');
        this.getFiles();
      }
    })
  }

  /** Build forms for this page */
  private buildForms() {
    const generateSearchForm = () => this.formBuilder.group({
      fileName: ['', ],
      mediaName: ['', ],
      minSize: ['', ],
      maxSize: ['', ],
      hash: ['', ],
      filePath: ['', ],
      extension: ['', ],

      minAccessDate: ['', ],
      maxAccessDate: ['', ],
      minCreateDate: ['', ],
      maxCreateDate: ['', ],
      minModifyDate: ['', ],
      maxModifyDate: ['', ],
    });

    this.searchForm = generateSearchForm();
    this.taggedSearchForm = generateSearchForm();

    this.recordLimitForm = this.formBuilder.group({
      recordlimit: [PageConfig.searchLimitOptions[0].value]
    });
  }
  
  preventDefault(e) {
    e.preventDefault();
  }

  updateRecords(limit:number){
    this.checkedRecords = [];
    this.msg='Loading...';
    this.loading = true;
    this.currentPage = 1;
    this.pagination['limit'] = limit;
    this.allSelected = false;
    this.getFiles();
  }

  onSubmit() {
    //add all filled out form fields to active search filter list for displaying to user
    const form = this.taggedOnly ? this.taggedSearchForm : this.searchForm;
    this.activeSearchFilters = {};

    for(const controlName in form.value) {
      const control = form.get(controlName);
      if(control.value) { this.activeSearchFilters[controlName] = control.value; }
    }

    this.submitted = true;
    this.alert.remove();
    this.searchApplied = false;
    this.currentPage = 1;

    // reset to force getFiles to get count for new form data
    if(this.getFilesSubscription) { this.getFilesSubscription.unsubscribe(); }
    this.totalRecords = this.activeProject.totalFileCount

    this.getFiles();
  }
  
  /** Reset relative form then refetch files */
  resetForm() {
    this.activeSearchFilters = {};
    this.taggedOnly ? this.taggedSearchForm.reset() : this.searchForm.reset();
    this.searchApplied = false;
    this.alert.remove();
    this.allRecords = false;
    this.allSelected = false; 
    this.checkedRecords = [];
    this.currentPage = 1;
    this.msg = undefined;

    // reset to force getFiles to get count for new form data
    if(this.getFilesSubscription) { this.getFilesSubscription.unsubscribe(); }
    this.totalRecords = this.activeProject.totalFileCount

    this.getFiles();
  }
  
  /** Fetch relevant files */
  getFiles() {
    if(this.activeProject === null) {
      alert('Please select a project');
      return;
    }

    this.msg='Loading...';
    this.loading = true;
    let formData = this.taggedOnly ? { ...this.taggedSearchForm.value } : { ...this.searchForm.value };
    
    // remove period from beginning of file extension filter if there is one
    if(formData.extension && formData.extension[0] === '.') {
      formData.extension = formData.extension.slice(1, formData.extension.length);
    }

    // get rid of irrelevant form data
    Object.keys(formData).forEach(key => {
      if(!formData[key]) { delete formData[key]; }
    })

    const searchOpts: SearchOptions = {
      limit: this.pagination.limit,
      pageNumber: this.currentPage,
      sortAttr: this.sortMethod,
      sortDirection: this.sortDirection,
      taggedOnly: this.taggedOnly,
    }

    // fetch all files that match the project id and search params
    this.files = [];
    this.getFilesSubscription = this.searchService.getFiles(this.activeProject.id, formData, searchOpts).subscribe(
      ({ files, count, analytics }) => {
        this.files = files;
        this.fileAnalytics = analytics;
        this.loading = false;
        
        this.totalRecords = !Object.keys(formData).length && !this.taggedOnly ? 
          this.activeProject.totalFileCount : 
          count;

        this.msg = this.totalRecords === 0 ? 'No files found' : undefined;
      }
    );
  }

  download() {
    if(this.downloadStarted){
      this.dialog.show('Alert','You cannot request again while downloading is in progress. I appreciate you patience');
      return false;
    }
    
    // if not on tagged page and no records are selected, show warning
    if(!this.taggedOnly && !this.checkedRecords.length) {
      this.dialog.show('Warning','Please select records to download.');
      return false;
    }

    const projectId = this.pid;
    const customerId = this.cid;
    const fileIds = [...this.checkedRecords]

    let observable: Observable<any>;

    // if in tagged page and there are no records selected, download all tagged records
    if(this.taggedOnly && this.checkedRecords.length === 0 && this.files.length > 0) {
      // confirm user wants to download
      const confirmed = confirm(`Download all tagged records to SpreadSheet?`);
      if(!confirmed) return;

      // make observable downloadAllTaggedRecords function
      observable = this.searchService.downloadAllTaggedRecords(projectId);
    }
    else {
      // confirm user wants to download
      const confirmed = confirm(`Download ${this.checkedRecords.length} records to SpreadSheet?`);
      if(!confirmed) return;
      
      // make observable download function
      observable = this.searchService.download(projectId, fileIds);
    }

    this.downloadStarted = true;

    observable.subscribe(
      ({ table: csvContent }) => {
        this.downloadStarted = false;
        this.excel.generateExcelFile(csvContent, this.taggedOnly);
        this.allRecords = false;
        this.allSelected = false;
        this.checkedRecords = [];
        alert('Download Successful');
      },
      (data:any) => {
        this.downloadStarted = false;
        this.dialog.show('Error', 'Something went wrong!');
      });
  }

  toggleAdform(){
    this.showAdfrom = !this.showAdfrom;
    if(this.showAdfrom)  
      this.iconClass = "fa-search-minus";
    else
      this.iconClass = "fa-search-plus";
  }

  closeAdavanceFrom(e){
    this.uiservice.overlay('hide');
    let advanceForm = document.querySelector('#navsearch');
    advanceForm.classList.remove('active');
  }

  correctYear(e){
    if(e.target.value.length < 9){
      alert('Please enter date in mm/dd/yyyy format.');
    }
  }

  sort(property: FileSortMethod) {
    // if property is the same as current, switch sort direction
    if(this.sortMethod === property) {
      this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.sortMethod = property;
      this.sortDirection = 'ASC';
    }

    return this.getFiles()
  };

  /** If checking all rows, add all files to checked records, otherwise clear check records list */
  checkAllRows(event: any) {
    const checked = event.target.checked;
    this.allSelected = checked;
    
    this.checkedRecords = checked ? this.files.map(file => file.id) : [];
  }

  /** Determines if a record is currently checked */
  isRecordChecked(fileId: string) {
    return this.checkedRecords.some(id => fileId === id);
  }
  
  /** Toggle checking a record and push/remove record id to/from checkedRecords list */
  toggleRecordCheck(e: any, fileId: string){
    const checked = e.target.checked;

    // if checked, add record to checkRecordsList
    if(checked) {
      this.checkedRecords.push(fileId);
    }
    // otherwise remove record id from check records list 
    else {
      const valueIndex = this.checkedRecords.indexOf(fileId);
      this.checkedRecords.splice(valueIndex, 1);
    }
    
    if(!checked){
      this.allSelected = false;
    }
  }

  /** Tag all currently checked items */
  tagItems() {
    if(this.tagFormSubmitted){
      this.dialog.show('Alert','You cannot request again while tagging is in progress. I appreciate your patience');
      return false;
    }
    this.tagFormSubmitted = true;

    // tag items
    this.searchService.tagItems(this.activeProject.id, this.checkedRecords).subscribe(
      ({ tagged }) => {
        this.tagFormSubmitted = false;
        this.dialog.show('Success', `Successfully Tagged ${tagged} Files`);
        // if just tagged, add tagged attr to files
        this.files.forEach(file => {
          this.checkedRecords.indexOf(file.id) !== -1 ? file.tagged = true : null;
        })
        this.checkedRecords = [];
      },
      (error) => {
        this.tagFormSubmitted = false;
        this.dialog.show('Error', 'Something went wrong!');
      });
  }

  /** Un-tag all currently checked records */
  untagItems() {
    if(this.tagFormSubmitted){
      this.dialog.show('Alert','You cannot request again while tagging is in progress. I appreciate your patience');
      return false;
    }
    this.tagFormSubmitted = true;

    // tag items
    this.searchService.untagItems(this.activeProject.id, this.checkedRecords).subscribe(
      ({ untagged }) => {
        this.tagFormSubmitted = false;
        this.dialog.show('Success', `Successfully Untagged ${untagged} Files`);
        this.files = this.files.filter(file => this.checkedRecords.indexOf(file.id) === -1);
        this.checkedRecords = [];
      },
      (error) => {
        this.tagFormSubmitted = false;
        this.dialog.show('Error', 'Something went wrong!');
      });
  }

  pageChanged($event){
    this.currentPage = $event;
    this.getFiles();
  }

  tagChange() {
    this.allSelected = false;
    this.onSubmit();
  }

  checkAllRecords(event){
    this.allRecords = event.target.checked;
    if(event.target.checked){
    sessionStorage.setItem("allRecords", "true");
    }else{
      sessionStorage.removeItem("allRecords");
    }
    this.checkAllRows(event);
  }

   /** Reset single form field and resubmit form */
   resetFormField(controlName: string) {
    // delete from active search filters object
    delete this.activeSearchFilters[controlName];

    // reset form control value
    const control = this.searchForm.get(controlName);
    control.setValue("");

    // resubmit form
    this.onSubmit();
  }

  formatDate({ year, month, day }: { year: number, month: number, day: number }): string {
    const month_1 = month >= 10 ? month : '0' + month;
    const day_1 = day >= 10 ? day : '0' + day;

    return `${month_1}/${day_1}/${year}`;
  }

  /** On chart click, submit form with extension value */
  onChartClick(val: string) {
    const control = !this.taggedOnly ?
      this.searchForm.get('extension') :
      this.taggedSearchForm.get('extension');

    control.setValue(val);
    this.onSubmit();
  }
}