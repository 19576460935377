// PACKAGES
import { createReducer, on } from '@ngrx/store';
// APP
import { setActiveProject, clearActiveProject } from './projects.actions';
import { Project } from '../../../types/Project';

export interface ActiveProjectState {
    activeProject: Project | null;
}

const initialState: ActiveProjectState = {
    activeProject: null,
}

const _activeProjectReducer = createReducer(initialState, 
    on(setActiveProject, (_, payload) => ({ activeProject: payload.project })),
    on(clearActiveProject, (state) => ({ activeProject: null }))
);

export function activeProjectReducer(state, action) {
    return _activeProjectReducer(state, action);
}