import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountUpdateService {

  constructor() { }
  
  totalCustomer = new Subject<any>();
  totalUser = new Subject<any>();
  totalProject = new Subject<any>();
  currentProject = new Subject<any>();
  showBack = new Subject<any>();
  removeBack = new Subject<any>();
}
