import { Component, Input, Output, OnChanges, OnInit, EventEmitter, HostBinding } from '@angular/core';
import { FileQueryReturnObject } from '../../../_services/search.service';

@Component({
  selector: 'app-search-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class SearchAnalyticsComponent implements OnInit, OnChanges {
  @Input() analytics: FileQueryReturnObject['analytics'];
  @Output() onClick: EventEmitter<string> = new EventEmitter();

  showAnalytics: boolean = true;
  topExtensions: { name: string, value: number }[] = [];

  chartOpts = {
    view: [ 300, 175 ],
    scheme: "cool",
    showAnimations: true,
    showLegend: true,
    legendPosition: 'right',
    showLabels: false,
  }

  constructor() { }

  ngOnInit() {
    if(this.analytics && this.analytics.topExtensions)
      this.topExtensions = this.analytics.topExtensions.map(({ extension, count }) => ({ name: extension, value: count }))
  }

  ngOnChanges() {
    if(this.analytics && this.analytics.topExtensions)
      this.topExtensions = this.analytics.topExtensions.map(({ extension, count }) => ({ name: extension, value: count }))
  }

  onChartClick(e: { name: string } | string) {
    const val = typeof e === 'string' ? e : e.name;
    this.onClick.emit(val);
  }

  toggleDisplay() {
    this.showAnalytics = !this.showAnalytics;
  }

}
