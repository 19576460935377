// PACKAGES
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// APP
import { InvData, PathInfo } from "src/app/dashboard/file-explorer-page/file-explorer-page.types";
import { FileExplorerService } from 'src/app/_services/file-explorer.service';
import { Project } from 'src/types/Project';

export type DateSortMethod = 'ACCESS_DATE' | 'CREATED_DATE' | 'MODIFY_DATE';
export type SortMethod = 'NAME' | 'SIZE' | DateSortMethod;
export type SortDirection = 'ASC' | 'DESC';

export type ViewMode = 'TABLE' | 'ICONS';

export interface FileExplorerState {
  activeProject: Project;
  backupData: InvData[];
  activeDirectory: InvData;
  selectedContent: InvData[];
  sortMethod: SortMethod;
  sortDirection: SortDirection;
  viewMode: ViewMode;
  infoPanelContent: InvData['details'] | null;
  expandedNavRowIds: string[];
}

const initialState: FileExplorerState = {
  activeProject: null,
  backupData: [],
  activeDirectory: null,
  selectedContent: [],
  sortMethod: 'NAME',
  sortDirection: 'ASC',
  viewMode: 'TABLE',
  infoPanelContent: null,
  expandedNavRowIds: [],
}

@Injectable({
  providedIn: 'root'
})
export class FileExplorerStateService {
  state = new BehaviorSubject<FileExplorerState>(initialState);

  constructor(private fileExplorerService: FileExplorerService) { }

  get explorerState() {
    return this.state.asObservable();
  }

  /** Get current state values */
  getCurrentState() {
    return this.state.getValue();
  }

  /** Set Active Project for file explorer reference */
  setActiveProject(project: Project, backup: InvData[]) {
    this.state.next({
      ...this.state.getValue(),
      activeProject: project,
      backupData: backup,
      activeDirectory: null,
      selectedContent: [],
      infoPanelContent: null,
      expandedNavRowIds: [],
    });
  }

  /** Set backup data */
  setBackupData(backup: InvData[]) {
    this.state.next({
      ...this.state.getValue(),
      backupData: backup,
    })
  }

  /** Set active directory and clear selected content */
  setActiveDirectory(directory: InvData) {
    const activeProject = this.getCurrentState().activeProject;

    // if directory has type directory, use getFolderContents function
    if(!directory.contentsFetched && directory.details.type === 'DIRECTORY') {
      directory.fetching = true;
      const { id: directoryId, hasFileChild, hasFolderChild } = <PathInfo>directory.details;
      
      // fetch folder contents using existing parent folder's id
      this.fileExplorerService.getFolderContents(activeProject.id, directoryId).subscribe(data => {
        directory.contentsFetched = true;
        directory.fetching = false;
        directory.contents = data.contents.map(record => new InvData(record, directory));

        this.state.next({ 
          ...this.state.getValue(),
          activeDirectory: directory,
          selectedContent: [],
          infoPanelContent: null,
        });
      });
    }
    // if directory has type "BACKUP", use getRootFolders function
    else if(!directory.contentsFetched && directory.details.type === 'BACKUP') {
      directory.fetching = true;
      const { id: backupSessionId } = directory.details;

      // fetch root folder of a backup session using backup session id
      this.fileExplorerService.getRootFolders(activeProject.id, backupSessionId).subscribe(data => {
        directory.contentsFetched = true;
        directory.fetching = false;
        directory.contents = data.directories.map(record => {
          console.log(record);
          return new InvData(record, directory)
        });
        
        this.state.next({ 
          ...this.state.getValue(),
          activeDirectory: directory,
          selectedContent: [],
          infoPanelContent: null,
        });
      });
    }
    // if folder contents have already been fetched, switch active directory
    else {
      this.state.next({ 
        ...this.state.getValue(),
        activeDirectory: directory,
        selectedContent: [],
        infoPanelContent: null,
      });
    }
  }

  /** add or remove folder ID from list of expanded nav row IDs */
  toggleFolderExpansion(folderId: string) {
    const expandedRows = this.state.getValue().expandedNavRowIds;
    const idIndex = expandedRows.indexOf(folderId);

    // if expanded nav rows array has matching id, remove element
    if(idIndex > -1) {
      expandedRows.splice(idIndex, 1);
    }
    // otherwise add element to array
    else {
      expandedRows.push(folderId);
    }

    this.state.next({
      ...this.state.getValue(),
      expandedNavRowIds: expandedRows,
    });
  }

  /** Set details to display in file explorer info panel */
  setInfoPanelContent(details: InvData['details']) {
    this.state.next({
      ...this.state.getValue(),
      infoPanelContent: details,
    });
  }

  /** Set selected content for file explorer state */
  setSelectedContent(selected: InvData[]) {
    this.state.next({
      ...this.state.getValue(),
      selectedContent: selected,
    })
  }

  /** Set sort direction */
  setSortDirection(direction: SortDirection) {
    this.state.next({
      ...this.state.getValue(),
      sortDirection: direction,
    })
  }

  /** Sort set method */
  setSortMethod(method: SortMethod) {
    this.state.next({
      ...this.state.getValue(),
      sortMethod: method,
    })
  }
}
