import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AlertService, UIService } from 'src/app/_services';
import { CountService } from 'src/app/_services/count.service';
import { PartnersService } from 'src/app/_services/partners.service';
import { COUNTRY_LIST } from 'src/app/_utils/countries';
import { isLowercase } from 'src/app/_validators/isLowercase.validator';
import { isAlpha } from '../../../_validators/isAlpha.validator';
import { isPhoneNumberValidator } from 'src/app/_validators/isPhoneNumber.validator';

enum ViewType { NEW = 'new', EDIT = 'edit' }

@Component({
  selector: 'app-partner-form',
  templateUrl: './partner-form.component.html',
  styleUrls: ['./partner-form.component.scss']
})
export class PartnerFormComponent implements OnInit {

  constructor(
    private partnerService: PartnersService,
    private formBuilder: FormBuilder,
    private UIService: UIService,
    private countService: CountService,
    private alertService: AlertService,
    private router: Router,
    private location: Location,
  ) { }

  view: ViewType;
  viewType = ViewType;
  pageTitle: string;

  partnerForm: FormGroup;
  countries: string[] = COUNTRY_LIST;
  submitted: boolean = false;
  loading: boolean = false;
  showReturnButton: boolean = false;

  partnerId: string;

  ngOnInit() {
    // set page title and tab title based on the action being taken (view type)
    this.view = this.router.url.includes('partner/new') ? ViewType.NEW : ViewType.EDIT;
    this.pageTitle = this.view === ViewType.NEW ? 'Create Partner' : 'Edit Partner';
    this.UIService.setTitle(this.pageTitle);

    // build form for partner information
    this.partnerForm = this.formBuilder.group({
      name:     ['', Validators.required],
      uri:      ['', [Validators.required, isLowercase, isAlpha]],
      email:    ['', [ Validators.required, Validators.email ]],
      status:   ['', Validators.required],
      phone:    ['', isPhoneNumberValidator],
      country:  ['', Validators.required]
    });

    // if in edit mode, set existing form values
    console.log(this.view);
    if(this.view === ViewType.EDIT) {
      const partner = history.state.data.partner;
      this.partnerId = partner.id;
      console.log("WENT INTO HERE");

      this.partnerForm.setValue({
        name: partner.name,
        uri: partner.uri,
        email: partner.email,
        status: partner.status,
        phone: partner.phone || '',
        country: partner.country || '',
      })
    }
  }

  /** Go back to last page */
  navigateBack() {
    this.location.back();
  }

  onSubmit() {
    this.submitted = true;

    // stop process here if form is invalid
    if(this.partnerForm.invalid) {
      alert('Form is invalid. Please fix highlighted errors');
      return false;
    }

    this.loading = true;
    this.view === ViewType.EDIT && this.partnerId ? this.updatePartner() : this.createPartner();
  }

  /** Submit request to API to create partner */
  createPartner() {
    const submissionData = this.formatSubmissionData(this.partnerForm.value);
    this.partnerService.createPartner(submissionData).subscribe(
      () => {
        this.loading = false;
        this.submitted = false;
        this.partnerForm.reset();
        this.alertService.success('Partner created successfully!');
        this.showReturnButton = true;

        const currentCounts = this.countService.counts.getValue();
        this.countService.counts.next({ ...currentCounts, partners: currentCounts.partners + 1 })
      },
      ({ error }) => {
        this.loading = false;
        this.submitted = false;
        error.statusCode && error.statusCode === 400 ?
          this.alertService.error(error.message) :
          this.alertService.error("Something went wrong!");
      }
    );
  }

  /** Submit request to API to update partner */
  updatePartner() {
    const submissionData = this.formatSubmissionData(this.partnerForm.value);
    this.partnerService.updatePartner(this.partnerId, submissionData).subscribe(
      () => {
        this.loading = false;
        this.submitted = false;
        this.partnerForm.reset();
        this.alertService.success('Partner updated successfully!');
        this.showReturnButton = true;
      },
      ({ error }) => {
        this.loading = false;
        this.submitted = false;
        error.statusCode && error.statusCode === 400 ?
          this.alertService.error(error.message) :
          this.alertService.error("Something went wrong!");
      }
    )
  }

  // delete unnecessary/undefined object attributes
  formatSubmissionData(formData: any) {
    Object.keys(formData).forEach(key => {
      if(!formData[key]) delete formData[key];
    });

    return formData;
  }

}
