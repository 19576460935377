import { Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { IDLE_TIME } from './../../_config/config';
import { AlertService, AuthService, UIService } from './../../_services/index';
import { CountUpdateService } from '../../_services/count-update.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  username: string;
  isOpen = false;
  time:any;

  logo: string;
  logoSubjectListener: Subscription;

  constructor(
    public authService: AuthService,
    public countUpdate : CountUpdateService,
    private UIService: UIService,
    private router: Router) {
  }

  ngOnInit() {
    this.isOpen = false;
    this.inactivityTime();

    this.logoSubjectListener = this.UIService.logoSubject.subscribe(logo => {
      this.logo = logo;
    });
  }

  ngOnDestroy() {
    this.logoSubjectListener.unsubscribe();
  }

  changeName(name:any){
    this.username= name;
  }

  openDropdown() {  
    this.isOpen = !this.isOpen;
  }
  logout(){
    clearTimeout(this.time); 
    this.router.navigate(['/logout']);
  }
  inactivityTime() {
    clearTimeout(this.time);  
    const that = this;
    window.onload = function(){
      that.resetTimer();
    }
    // DOM Events
    document.onmousemove = function(){
      that.resetTimer();
    }
    document.onkeypress = function(){
      that.resetTimer();
    }
  }
  resetTimer() {
    if(this.authService.loggedIn){
      clearTimeout(this.time); 
      this.time = setTimeout(async ()=>{
        clearTimeout(this.time); 
        this.authService.logout().subscribe(async () => {
          await this.router.navigate(['/login'], { state: { message: `You have been idle for ${IDLE_TIME/60} minutes. Please login again`}});
        });
      }, IDLE_TIME*1000);
    }
  }

  removeBack(){
    this.countUpdate.removeBack.next(false);
  }
}
