// PACKAGES
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// APP
import { formatBytes } from 'src/app/_utils/utils';
import { FileInfo } from 'src/app/dashboard/file-explorer-page/file-explorer-page.types';
import { FileExplorerStateService } from 'src/app/_state/file-explorer/file-explorer-state.service';

/** helper class for displaying file info */
class FileAttrMap {
  constructor(details: FileInfo) {
    this.mediaLabel = details.media;
    this.modifyDate = details.modifyDate;
    this.accessDate = details.accessDate;
    this.createDate = details.createDate;
    this.hash = details.hash;
  }
  mediaLabel: string;
  modifyDate: number;
  accessDate: number;
  createDate: number;
  hash: string;
}

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent implements OnInit {
  showContent: boolean = false;
  iconName: string;

  _fileExplorerStateListener: Subscription;

  // details to show in info panel
  title: string;
  type: string;
  size: string;
  //--- BACKUP SPECIFIC ---
  backupDate: any;
  //--- FILE SPECIFIC ---
  fileAttrMap: FileAttrMap = null;

  constructor(private fileExplorerStateService: FileExplorerStateService) { }

  ngOnInit() {
    this._fileExplorerStateListener = this.fileExplorerStateService.explorerState.subscribe(({ infoPanelContent }) => {
      if(infoPanelContent === null) {
        this.showContent = false;
        return;
      }

      this.showContent = true;
      this.fileAttrMap = null;
      this.backupDate = null;

      this.title = infoPanelContent.name;
      this.size = 'size' in infoPanelContent ? formatBytes(infoPanelContent.size) : 'unavailable';
      this.type = this.formatTypeInfo(infoPanelContent.type);

      this.iconName = this.getCorrespondingIcon(infoPanelContent.type);

      if(infoPanelContent.type === 'FILE') {
        this.fileAttrMap = new FileAttrMap(infoPanelContent)
      } else if(infoPanelContent.type === 'BACKUP') {
        this.backupDate = infoPanelContent.backupDate;
      }
    });
  }

  /** Return Icon name based on the content type */
  private getCorrespondingIcon(type: string) {
    switch(type) {
      case 'FILE':
        return 'fa-file';
      case 'DIRECTORY':
        return 'fa-folder';
      default:
        return undefined;
    }
  }

  private formatTypeInfo(type: string) {
    return type === 'PARENT' ? 'Containing Folder' : type; 
  }

}
