// PACKAGES
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
// APP
import { InvData } from 'src/app/dashboard/file-explorer-page/file-explorer-page.types';
import { DialogService } from 'src/app/_services/dialog.service';
import { ExcelService } from 'src/app/_services/excel.service';
import { SearchService } from 'src/app/_services/search.service';
import { TimerService } from 'src/app/_services/timer.service';
import { FileExplorerState, FileExplorerStateService, SortDirection, SortMethod, ViewMode } from 'src/app/_state/file-explorer/file-explorer-state.service';
import { ActiveProjectState } from 'src/app/_state/projects/projects.reducer';
import { Project } from 'src/types/Project';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Input() noData: boolean;
  
  @Input() activeDirectory: InvData;
  @Input() selectedContent: InvData[];
  @Output() selectedContentChange = new EventEmitter();

  sortMethods: SortMethod[] = ['NAME', 'SIZE', 'CREATED_DATE', 'ACCESS_DATE', 'MODIFY_DATE'];
  sortDirections = ['ASC', 'DESC'];

  selectedCount: number;
  currentSortMethod: SortMethod;
  currentSortDirection: SortDirection;
  viewMode: ViewMode;

  activeProject: Project;

  _activeProjectStateListener: Subscription;
  _fileExplorerStateListener: Subscription;

  constructor( 
    private fileExplorerStateService: FileExplorerStateService,
    private searchService: SearchService,
    private dialogService: DialogService,
    private timerService: TimerService,
    private excel: ExcelService,
    private store: Store<{ activeProject: ActiveProjectState, fileExplorer: FileExplorerState }> 
  ) { }

  ngOnInit() {
    // listen for changes to file explorer state in ngrx store
    this._fileExplorerStateListener = this.fileExplorerStateService.explorerState.subscribe(({ selectedContent, sortMethod, sortDirection, viewMode }) => {
      this.currentSortMethod = sortMethod;
      this.currentSortDirection = sortDirection;
      this.viewMode = viewMode;
      this.selectedContent = selectedContent;
      this.selectedCount = this.selectedContent.length;
    });

    // listen for changes to active project state in ngrx store
    this._activeProjectStateListener = this.store.select('activeProject').subscribe(({ activeProject }) => {
      this.activeProject = activeProject;
    })
  }

  ngOnDestroy() {
    this._fileExplorerStateListener.unsubscribe();
    this._activeProjectStateListener.unsubscribe();
  }

  /** clear selected content and emit to parent component */
  clearSelectedContent() {
    this.fileExplorerStateService.setSelectedContent([]);
  }

  /** change to specified sorting method */
  changeSortingMethod(method: SortMethod) {
    this.fileExplorerStateService.setSortMethod(method);
  }

  /** change to specified sorting direction */
  changeSortingDirection(direction: SortDirection) {
    this.fileExplorerStateService.setSortDirection(direction);
  }

  /** Get file information and download CSV with file information */
  downloadFiles() {
    const numOfNonFileSelected = this.selectedContent.filter(content => content.details.type !== 'FILE').length;
  
    // if non file content is selected, show alert
    if(numOfNonFileSelected > 0) {
      alert("Only files can be downloaded to CSV \n Please only select the files you'd like to download and try again");
      return;
    }

    const projectId = this.activeProject.id;
    const customerId = this.activeProject.customer.id;
    const fileIds = this.selectedContent.map(record => record.details.id);

    // confirm user wants to download
    const confirmed = confirm(`Download ${fileIds.length} records to SpreadSheet?`);
    if(!confirmed) return;

    // get file data from API/CloudSearch
    this.searchService.download(projectId, fileIds).subscribe(
      ({ table: csvContent }) => {

        // download excel file to client
        this.excel.generateExcelFile(csvContent);
        
        //unselect all checked data
        this.fileExplorerStateService.setSelectedContent([]);

        // show download success message
        alert('Download Successful');
      },
      (err: any) => {
        this.dialogService.show('Error', 'Something went wrong!');
      }
    )
  }

  /** Tag files if there is valid records selected */
  onTagPress() {
    const numOfNonFileSelected = this.selectedContent.filter(content => content.details.type !== 'FILE').length;
  
    // if non file content is selected, show alert
    if(numOfNonFileSelected > 0) {
      alert("Only files can be tagged \n Please only select the files you'd like to tag and try again");
      return;
    } 

    // prompt to make sure user wants to tag selected items
    const numSelected = this.selectedContent.length;
    const confirmed = numSelected > 1 ?
      confirm(`Tag ${numSelected} files?`) :
      confirm(`Tag ${numSelected} file?`);

    // if no, return
    if(!confirmed) {
      return;
    }

    // Tag items
    this.searchService.tagItems(this.activeProject.id, this.selectedContent.map(content => content.details.id)).subscribe(
      ({ tagged }) => {
        // set selected contents as tagged without actually fetching again
        this.selectedContent.forEach((file: any) => file.details.tagged = true);
        this.dialogService.show('Success', `Successfully Tagged ${tagged} Files`);
        this.clearSelectedContent();
      },
      (err) => {
        console.error(err);
        this.dialogService.show('Error', 'Something went wrong!');
      }
    );
  }

}
