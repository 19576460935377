import { Component, OnInit } from '@angular/core';
import { UIService } from '../_services';
import { DialogService, DialogType } from '../_services/dialog.service';

@Component({
    selector: 'indialog',
    templateUrl: 'dialog.component.html',
    styleUrls: ['dialog.component.scss']
}) 

export class DialogComponent implements OnInit {
    props: any;
    closingTimerSecs = 4;
    percentage: number = 0;

    primaryColor: string;

    constructor(
        private dialogService: DialogService,
        private UIService: UIService,
    ) { }

    ngOnInit() {
        this.primaryColor = this.UIService.scheme.primary;

        this.dialogService.getProps().subscribe((props: { close: boolean, title: string, text: string, type: DialogType }) => { 
            this.props = props; 

            if(this.props && this.props.close) { this.closePopup(); return; };

            setTimeout(() => {
                var popup = document.getElementById('inpopup');
                if(popup) {
                    popup.style.display = 'block';
                    if(props.type !== "LOADING") this.startClosingTimer();
                }
            }, 300); 
        });
    }

    closePopup(){ 
        document.getElementById('inpopup').style.display = 'none';
    }

    // close popup after certain amount of time
    startClosingTimer() {
        this.percentage = 100;
        const interval = setInterval(() => {
            this.percentage -= 100 / this.closingTimerSecs;
        }, 1000);

        setTimeout(() => {
            this.closePopup();
            clearInterval(interval);
        }, this.closingTimerSecs * 1000);
    }
}