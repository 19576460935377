import { Component,ChangeDetectorRef } from '@angular/core';
import {formatDate } from '@angular/common';

@Component({
  selector: '[banner]',
  templateUrl: 'banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {

  today= new Date();
  todaysTime = '';
  todaysDate = '';
  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.todaysDate = formatDate(this.today, 'MMM dd, yyyy', 'en-US');
    this.todaysTime = formatDate(this.today, 'hh:mm a', 'en-US');

  }

}
