import { environment } from '../../environments/environment';

export const API_ENDPOINT = environment.api_endpoint;
export const SESSION_TIME = 1; // days
export const IDLE_TIME = 600; // 600 seconds = 10 mins
export const TAGGING_DELAY = 19000 // 19 seconds
export const APP = {
    host : 'localhost',
    adminEmailVendar : '@sullivanstrickler.com'
};
export const PageConfig = {
    enabled : false,
    limit : 15,
    startPage : 1,
    limitOptions : [
        { key: 15, value: 15, selected: true },
        { key: 30, value: 30, selected: false },
        { key: 50, value: 50, selected: false }
    ],
    searchLimitOptions : [
        { key: 100, value:100, selected: true },
        { key: 500, value:500, selected: false },
        { key: 1000, value:1000, selected: false }
    ]
}
