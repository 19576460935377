import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ProjectService } from 'src/app/_services/project.service';
import { CustomerService } from 'src/app/_services/customer.service';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { AlertService } from 'src/app/_services/alert.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { AuthService, UIService } from 'src/app/_services';
import { Customer } from 'src/types/Customer';
import { Project } from 'src/types/Project';
import { User } from 'src/types/User';
import { isPhoneNumberValidator } from 'src/app/_validators/isPhoneNumber.validator';
import { CountService } from 'src/app/_services/count.service';
import { PartnersService } from 'src/app/_services/partners.service';
import { PartnerPublicInfo } from 'src/types/Partner';
import { Role } from 'src/app/_models/role';

export type UserFormViewType = 'edit' | 'assign' | 'new';

@Component({
  selector: 'user-form',
  templateUrl: './userform.component.html',
  styleUrls: ['./userform.component.scss']
})
export class UserformComponent implements OnInit {
  constructor(
    private customerService : CustomerService,
    private projectService : ProjectService,
    private partnerService: PartnersService,
    public  authService: AuthService,
    private formBuilder: FormBuilder,
    private uiservice : UIService,
    private route: ActivatedRoute,
    private location: Location,
    private countService: CountService,
    private alert: AlertService,
    private userService : UserService
  ) { }
  
  isAdminEmail : boolean = false;
  // Page element setting
  pageTitle : string;
  buttonTitle : any;
  userview: UserFormViewType;

  // Form settings and configuration
  userForm: FormGroup;
  submitted = false;
  formData = {};
  loading : any;
  msg : any;
  urlParams : object;

  // Multidropdown settings
  selectedItems = [];
  dropdownSettings = {};
  dropdownList = [];
  projectsIdsArray = [];
  
  // Client information
  projects: Project[];
  partners: PartnerPublicInfo[];
  userId : string;
  allCustomers: any[];
  customersList: any[];
  assignedCustomer: Customer;

  public Role = Role;
  showReturnButton: boolean = false;

  ngOnInit() {
    // build page based on view type passed from previous page
    this.userview = this.route.snapshot.paramMap.get('type') as UserFormViewType;
    switch(this.userview) {
      case 'edit':
        this.pageTitle = 'Edit User';
        this.buttonTitle = 'update user';
        this.uiservice.setTitle('Edit User');
        break;
      case 'assign':
        this.pageTitle = 'Assign Projects';
        this.buttonTitle = 'assign projects';
        this.uiservice.setTitle('Assign Projects');
        break;
      default: // 'new' view
        this.getCustomers();
        this.userview = 'new';
        this.pageTitle = 'Create User';
        this.buttonTitle = 'create user';
        this.uiservice.setTitle('Create User');
    }

    // set dropdown settings
    this.dropdownSettings = {
      singleSelection: false, 
      text: "Select Projects",
      selectAllText:'Select All',
      unSelectAllText:'Unselect All',
      enableSearchFilter: false,
      classes:"myclass custom-class",
      disabled: !this.projects,
    }
    
    // set dropdown settings
    this.dropdownSettings = {
      singleSelection: false, 
      text: "Select Projects",
      selectAllText:'Select All',
      unSelectAllText:'Unselect All',
      enableSearchFilter: false,
      classes:"myclass custom-class",
      disabled: !this.projects,
    }
    
    // build and, if necessary, populate form based on view type
    this.buildForm();
    this.userview === 'edit' || this.userview === 'assign' ? this.setExistingFormValues() : null;
  }

  /** Build user form based on view type */
  private buildForm() {
    if(this.userview === 'assign') {
      this.userForm = this.formBuilder.group({
        projects: [''],
      });
      
      return;
    }

    // if logged in user is superadmin, allow partner input
    // otherwise, only allow current client partner to be assigned
    this.partners = this.partnerService.partnerList;
    if(!this.partners) {
      this.getPartnerList();
    }
    const initialPartnerValue = this.authService.isSuperadmin ?
      '' : { value: this.partnerService.partner.id, disabled: true };


    // if logged in user is customer admin, lock customer input to assigned customer
    const initialCustomerValue = this.authService.isCustomerAdmin ?
    { value: this.customerService.customer.id, disabled: true } : '';
  
    this.userForm = this.formBuilder.group({
      partner: [initialPartnerValue, Validators.required],
      customer: [initialCustomerValue],
      projects: [''],
      status: ['', Validators.required],
      role : ['', Validators.required],
      phone: ['', isPhoneNumberValidator],
      email: ['', [Validators.required, Validators.email]]
    });

    this.userForm.get('partner').valueChanges.subscribe(partnerId => {
        this.userForm.get('customer').setValue('');
        this.customersList = this.allCustomers.filter(c => c.partner.id === partnerId)
    })
  }

  /** If in edit or assign mode, set existing user form values */
  private setExistingFormValues() {
    const user = history.state.data.user;
    this.userId = user.id;
    this.assignedCustomer = user.customer;

    if(user.role === Role.ReadOnly) {
      // fetch all projects for the existing/assigned customer
      this.getCustomerProjects(this.assignedCustomer.id);

      // if in assign mode, set already assigned project values
      if(this.userview === 'assign') {
        this.userForm.setValue({
          projects: user.projects.map((project: Project) => ({ id: project.id, itemName: project.name }))
        });
      }
    }

    // if in edit mode, set existing form values
    if(this.userview === 'edit') {
      this.userForm.setValue({
        partner: user.partner,
        email: user.email,
        status: user.status,
        role: user.role,
        // format for angular2-multiselect dropdown
        projects: !user.projects ? '' : user.projects.map((project: Project) => ({ id: project.id, itemName: project.name })),
        customer: user.customer ? user.customer.id : '',
        phone: user.phone || '',
      });

      this.userForm.get('role').disable();
    }
  }

  /** Map projects to select options */
  getProjectCallback() {
    // map projects to the dropdown list
    this.dropdownList = this.projects.map(project => ({ id: project.id, itemName: project.name }));   
    // set dropdown settings
    this.dropdownSettings = { 
      singleSelection: false, 
      text: "Select Projects",
      selectAllText:'Select All',
      unSelectAllText:'Unselect All',
      enableSearchFilter: false,
      classes:"myclass custom-class",
      disabled : false
    };    
  }

  /** Navigate to previous page */
  navigateBack() {
    this.location.back();
  }

  pushProjectsInFormData(projects:any){
    this.projectsIdsArray = [];
    for (var obj of projects) {
        this.projectsIdsArray.push(obj.id);
    }
    console.log(this.projectsIdsArray);
  }

  /** Get partner list */
  getPartnerList() {
    this.partnerService.getAllPartnersPublicInfo().subscribe(({ partners }) => {
      this.partners = partners;
    })
  }

  /** On form Submission */
  onSubmit() {
    this.submitted = true;

    // stop the process here if form is invalid
    if (this.userForm.invalid) {
      alert('Form Invalid. Please fix the highlighted issues');
      return false;
    }

    if(this.userview === 'edit')    return this.updateUser();
    if(this.userview === 'assign')  return this.assignProjects();
    if(this.userview === 'new')     return this.createUser();  
  }

  /** Send create user request */
  createUser() {
    // format submission data
    const formData = this.userForm.getRawValue();
    Object.keys(formData).forEach(key => {
      !formData[key] ? delete formData[key] : null;
    });

    const user: User = { ...formData };
    !formData.projects ? null : user.projects = formData.projects.map(proj => proj.id);

    // Send create request
    this.userService.create(user).subscribe(
      ({ user }) => {
        this.alert.success('You have successfully created a User. We have sent the password to them via email.');
        this.submitted = false;
        this.userForm.reset();
        this.showReturnButton = true;
        
        // add 1 to users count
        const currentCounts = this.countService.counts.getValue();
        this.countService.counts.next({
          ...currentCounts,
          users: currentCounts.users + 1,
        });
      },
      ({ error }) => {
        this.submitted = false;
        error.statusCode === 400 ?
          this.alert.error(error.message) :
          this.alert.error('Something went wrong!');
      }
    );
  }

  /** Send update user request */
  updateUser() {
    // format submission data
    const formData = this.userForm.getRawValue();
    Object.keys(formData).forEach(key => {
      !formData[key] ? delete formData[key] : null;
    });

    console.log(formData);
    const user: User = { ...formData };
    !formData.projects ? null : user.projects = formData.projects.map(proj => proj.id);

    // send update request
    this.userService.update(this.userId, user).subscribe(
      () => {
        this.alert.success('User successfully updated');
        this.submitted = false;
        this.userForm.reset();
        this.showReturnButton = true;
      },
      (err) => {
        console.error(err);
        this.submitted = false;
        this.alert.error('Something went wrong!');
      }
    );
  }

  /** Update projects list for user */
  assignProjects() { 
    const projectList = this.userForm.value.projects.map(project => project.id);
    this.userService.assignProjects(projectList, this.userId).subscribe(
      () => {
        this.alert.success('Successfully assigned user to projects');
        this.submitted = false;
        this.userForm.reset();
        this.showReturnButton = true;
      },
      (err) => {
        console.error(err);
        this.submitted = false;
        this.alert.error('Something went wrong!');
      }
    )
  }

  /** Get all customers */
  getCustomers() {
    // if customer admin, no need to fetch other customer records
    if(this.authService.isCustomerAdmin) {
      this.allCustomers = [this.customerService.customer];
      this.getCustomerProjects(this.customerService.customer.id);
      return;
    }

    this.loading= true;
    this.msg = "Loading...";
    this.customerService.getCustomers(100).subscribe(
      ({ customers }) => {
          this.loading = false;
          // if not superadmin, must set customer list now
          // because partner value will not change
          if(!this.authService.isSuperadmin) {
            this.allCustomers = customers;
            this.customersList = customers;
          } else {
            // if superadmin, customer selection options
            // will be handled by partner field onChange handler
            this.allCustomers = customers;
          }
      },
      (data:any) => {
          this.msg = "Something went wrong";
      }
    );
  }

  /** get all projects that belong to a customer */
  private getCustomerProjects(customerId: string) {
    this.projectService.getCustomerProjects(customerId).subscribe(
      ({ projects }) => {
        this.projects = projects;
        this.loading = false;
        this.getProjectCallback();
      },
      (err) => {
        console.error(err);
        this.loading = false;
        this.msg = "Something went wrong";
      }
    )
  }

  /** When Role Input field value is changed, do the following */
  onRoleChange() {
    const roleControl = this.userForm.get('role');
    const customerControl = this.userForm.get('customer');
    if(this.submitted && roleControl.value === Role.ReadOnly && !customerControl.value) {
      // allow time for field to render, then set error
      setTimeout(() => customerControl.setErrors({ required: true }), 300);
      return;
    }

    this.userForm.get('projects').reset('');

    // only reset customers and projects list if user is at least partner admin AND role !== Read Only
    if(this.authService.hasAccess(Role.PartnerAdmin)) {
      this.userForm.get('customer').reset('');

      this.projects = [];
      this.dropdownList = [];
      this.dropdownSettings = {
        ...this.dropdownSettings,
        disabled: true,
      }
    }
  }

  /** When customer changes in form, get target customer's projects */
  onCustomerChange(customerId: string){
    this.dropdownList = [];
    this.selectedItems = [];
    this.loading = true;
    this.msg = 'loading';

    this.getCustomerProjects(customerId);
  }

  onItemSelect(item:any){
    this.pushProjectsInFormData(this.selectedItems);
  }
  OnItemDeSelect(item:any){
      this.pushProjectsInFormData(this.selectedItems);
  }
  onSelectAll(items: any){
    this.pushProjectsInFormData(items);
  }
  onDeSelectAll(items: any){
    this.pushProjectsInFormData(items);
  }
}
