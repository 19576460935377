import { Component, OnInit } from '@angular/core';
import { MediaService } from 'src/app/_services/media.service';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { AlertService } from 'src/app/_services/alert.service';
import { ActivatedRoute } from '@angular/router';
import { UIService } from 'src/app/_services';

@Component({
  selector: 'app-mediasearch',
  templateUrl: './mediasearch.component.html',
  styleUrls: ['./mediasearch.component.scss']
})
export class MediasearchComponent implements OnInit {

  constructor(
    private mediaService: MediaService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private uiservice : UIService,
    private alert: AlertService
  ) { }

  // Form settings and configuration
  mediaSearchForm: FormGroup;
  submitted = false;
  formData = {};
  loading : any;
  msg : any;
  mediaResult : [];
  mediatypes: any = [];
  filterApplied: boolean = false;

  uid: any;
  pid:any;

  ngOnInit() {    
    this.uiservice.setTitle('Media Listing'); 
    this.uid = this.route.snapshot.paramMap.get('id');
    this.pid = this.route.snapshot.paramMap.get('pid');
    // Form submission
    this.formValidation();
    this.getMediatypes();
  }
  formValidation(){
    this.mediaSearchForm = this.formBuilder.group({
      mediaLabel: ['', ],
      mediaTypeName: ['',]
    });
  }
  onSubmit() {
    this.submitted = true;
    this.alert.remove();
    // stop the process here if form is invalid
    if (this.mediaSearchForm.invalid) {
        console.log('cant be blank');
        return false;
    } 

    this.getMediaFiles();
  }
  getMediaFiles(){
    this.loading = true;
    this.msg = 'Loading...';
    this.filterApplied = true;
    this.mediaResult = []; console.log(this.mediaSearchForm.value);
    this.mediaService.getMediaFiles(this.uid, this.pid, this.mediaSearchForm.value)
      .subscribe(
      (data:any) => {
        if(data.response.Items.length > 0){
          this.loading = false;
          this.mediaResult = data.response.Items;
        }else{
          this.msg = "<span class='text-danger'>No record found.</span>";
        }
      },
      (data:any) => {
        this.msg = "<span class='text-danger'>Something went wrong.</span>";
      });
  }
  getMediatypes(){
    this.mediaService.getMediaTypes(this.uid, this.pid)
      .subscribe(
      (data:any) => {
        if(data.response.Items.length > 0){
          this.mediatypes = data.response.Items;
        }
      },
      (data:any) => {
        console.log('Something went wrong!');
      });
  }

}
