// PACKAGES
import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
// APP
import { InvData } from '../dashboard/file-explorer-page/file-explorer-page.types';
import { FileExplorerState, FileExplorerStateService } from '../_state/file-explorer/file-explorer-state.service';

@Component({
  selector: 'app-file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.scss']
})
export class FileExplorerComponent implements OnInit, OnDestroy {
  @Input() loading: boolean;

  backup: InvData[];
  activeDirectory: InvData;
  selectedContent: InvData[];

  _fileExplorerStateListener: Subscription;

  constructor(private fileExplorerStateService: FileExplorerStateService) { }

  ngOnInit() {
    this._fileExplorerStateListener = this.fileExplorerStateService.explorerState.subscribe(({ backupData, activeDirectory, selectedContent }) => {
      this.backup = backupData;
      this.activeDirectory = activeDirectory;
      this.selectedContent = selectedContent;
    });
  }

  ngOnDestroy() {
    this._fileExplorerStateListener.unsubscribe();
  }

  /** when a child component sets active directory, match changes here also */
  onChildSetsActiveDirectory(activeDirectory: InvData) {
    this.clearSelectedContent(); 
    this.activeDirectory = activeDirectory;
  }

  /** clear selected content in the explorer contents */
  clearSelectedContent() {
    this.fileExplorerStateService.setSelectedContent([]);
  }
}
