import { Component, OnInit, Input } from '@angular/core';
import { MediaService } from 'src/app/_services/media.service';
export interface Media {
  id: string,
  name: string;
  children: Media[];
}


@Component({
  selector: 'tree-list',
  template: `<ul *ngIf="media.children.length > 0" class="nested">
  <li *ngFor="let media of media.children">
  <a *ngIf="media.children.length" class="has-child">{{media.name}}</a>
  <a *ngIf="!media.children.length" [routerLink]="['/dashboard/mediabackup/', media.id, media.name]" class="no-child">{{media.name}}</a>
      <tree-list [media]="media"></tree-list>
  </li> 
</ul>`
})

export class TreeListComponent{

  @Input() media: Media;
}

@Component({
  selector: 'app-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.scss']
})
export class TreeviewComponent implements OnInit {
  
  constructor(
    private mediaService : MediaService
  ) { }
  public backup = [
    {id: 1, name: 'Tapes', children : [
       {id : 1 , name : 'Tap A(Media ID)', children : [
        {id : 3 , name : 'Session 1', children : [{ id : 1, name : 'File_20_Jan', children: [] }] },
        {id : 4 , name : 'Session 2', children : [{ id : 2, name : 'File_04_April', children: [] }] },
        {id : 5 , name : 'Session 3', children : [{ id : 3, name : 'File_31_March', children: [] }] },
        {id : 6 , name : 'Session 4', children : [{ id : 4, name : 'File_22_July', children: [] }] },
        {id : 7 , name : 'Session 5', children : [{ id : 5, name : 'File_27_Dec', children: [] }] }
       ]},
       {id : 2 , name : 'Tap B(Media ID)', children : [
        {id : 3 , name : 'Session 1', children : [{ id : 1, name : 'File Explorer', children: [] }] },
        {id : 4 , name : 'Session 2', children : [{ id : 2, name : 'File Explorer', children: [] }] },
        {id : 5 , name : 'Session 3', children : [{ id : 3, name : 'File Explorer', children: [] }] },
        {id : 6 , name : 'Session 4', children : [{ id : 4, name : 'File Explorer', children: [] }] },
        {id : 7 , name : 'Session 5', children : [{ id : 5, name : 'File Explorer', children: [] }] }
       ] },
       {id : 3 , name : 'Tap C(Media ID)', children : [
        {id : 3 , name : 'Session 1', children : [{ id : 1, name : 'File Explorer', children: [] }] },
        {id : 4 , name : 'Session 2', children : [{ id : 2, name : 'File Explorer', children: [] }] },
        {id : 5 , name : 'Session 3', children : [{ id : 3, name : 'File Explorer', children: [] }] },
        {id : 6 , name : 'Session 4', children : [{ id : 4, name : 'File Explorer', children: [] }] },
        {id : 7 , name : 'Session 5', children : [{ id : 5, name : 'File Explorer', children: [] }] }
       ] }
    ] },

    {id: 1, name: 'Hard Drives', children : [
      {id : 1 , name : 'HDD A', children : [
       {id : 3 , name : 'Files Explorer 1', children : [] },
       {id : 4 , name : 'Files Explorer 2', children : [] },
       {id : 5 , name : 'Files Explorer 3', children : [] }
      ]},
      {id : 2 , name : 'HDD B', children : [
        {id : 3 , name : 'Files Explorer 1', children : [] },
        {id : 4 , name : 'Files Explorer 2', children : [] },
        {id : 5 , name : 'Files Explorer 3', children : [] }
      ] },
      {id : 3 , name : 'HDD C', children : [
        {id : 3 , name : 'Files Explorer 1', children : [] },
        {id : 4 , name : 'Files Explorer 2', children : [] },
        {id : 5 , name : 'Files Explorer 3', children : [] }
      ] }
   ] }
  ];
  ngOnInit() {
    setTimeout(()=>{ 
      this.bindTree();
    },2000);

  }
  bindTree(){
    var toggler = document.getElementsByClassName("has-child");
    var i;

    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function() {
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("caret-down");
      });
    }
  }
  toggleTree(id:any){
    const subtree = document.getElementById('subtree'+id); 
    const treeicon = document.getElementById('treeicon'+id); 
    if(subtree.style.display == "" || subtree.style.display == 'none'){
      subtree.style.display = 'block';
      treeicon.classList.remove('fa-plus-circle');
      treeicon.classList.add('fa-minus-circle');
    }else{
      subtree.style.display = 'none';
      treeicon.classList.remove('fa-minus-circle');
      treeicon.classList.add('fa-plus-circle');
    }
  }
  getTree(){
  }

}
