import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { isNumber, toInteger, padNumber } from './../_utils/utils';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return {month: toInteger(dateParts[0]), day: null, year: null};
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return {month: toInteger(dateParts[0]), day: toInteger(dateParts[1]), year: null};
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        
        // transform 2 digit year to 4 digit year
        let yearInput = toInteger(dateParts[2]);
        if(yearInput >= 0 && yearInput < 70) {
          yearInput += 2000;
        } else if(yearInput >= 70 && yearInput < 100) {
          yearInput += 1900;
        }

        return {month: toInteger(dateParts[0]), day: toInteger(dateParts[1]), year: yearInput};
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date ?
        `${isNumber(date.month) ? padNumber(date.month) : ''}/${isNumber(date.day) ? padNumber(date.day) : ''}/${date.year}` :
        '';
  }
}