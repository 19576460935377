import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { API_ENDPOINT } from './../_config/config';
import { Project } from 'src/types/Project';
import { PartnersService } from './partners.service';
import { AuthService } from './auth.service';
import { CustomerService } from './customer.service';

@Injectable({
    providedIn: 'root'
  })
export class ProjectService {
    token:string;
    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private customerService: CustomerService,
        private partnerService: PartnersService,
    ) { }

    /** Get all projects */
    getProjects(limit: number, pageNumber: number = 1, sortAttr: string = 'name', sortDirection: 'ASC' | 'DESC' = 'ASC', nameSearch?: string) {
        const offset = limit * (pageNumber - 1);

        let params = new HttpParams()
            .set('limit', limit.toString())
            .set('offset', offset.toString())
            .set('sortAttr', sortAttr)
            .set('sortDirection', sortDirection);
        params = nameSearch ? params.append('name', nameSearch) : params;

        if(this.authService.isSuperadmin)    return this.getAllProjects(params);
        if(this.authService.isPartnerAdmin)  return this.getProjectsByPartner(params);
        if(this.authService.isCustomerAdmin) return this.getProjectsByCustomer(params);
    }

    private getAllProjects(params: HttpParams) {
        return this.http.get<{ projects: Project[], count: number }>(`${API_ENDPOINT}/projects/all`, { params });
    }

    private getProjectsByCustomer(params: HttpParams) {
        const customerId = this.customerService.customer.id;
        return this.http.get<{ projects: Project[], count: number }>(`${API_ENDPOINT}/customers/${customerId}/projects`, { params });
    }

    private getProjectsByPartner(params: HttpParams) {
        const partnerId = this.partnerService.partner.id;
        return this.http.get<{ projects: Project[], count: number }>(`${API_ENDPOINT}/partners/${partnerId}/projects`, { params });
    }

    /** Get projects that belong to a specific customer */
    getCustomerProjects(customerId: string) {
        return this.http.get<{ projects: Project[] }>(`${API_ENDPOINT}/customers/${customerId}/projects`);
    }

    /** Create new project in Database */
    create({ name, customer: customerId, description}: { name: string, customer: string, description?: string }) {
        const body = { name, customerId, description }
        return this.http.post<{ project: Project }>(`${API_ENDPOINT}/projects`, body);
    }

    update(projectId: string, projectAttr: { name: string, description: string, status: 'active' | 'inactive'; }) {
        const body = { id: projectId, ...projectAttr };
        return this.http.patch<{ project: Project }>(`${API_ENDPOINT}/projects`, body);
    }

    /** Delete project from database */
    delete(projectId: string) {
        return this.http.delete<{ message: string }>(`${API_ENDPOINT}/projects/${projectId}`);
    }


    /** Assign project to user(s) */
    assignUsers(userIds: string[], projectId: string) {
        return this.http.patch<{ message: string }>(`${API_ENDPOINT}/users/assign`, { userIds, projectId });
    }

    /** Force caching of projects analytics */
    cacheProjectAnalytics(projectId: string) {
        return this.http.put<Project['cachedAnalytics']>(`${API_ENDPOINT}/projects/${projectId}/cache`, undefined);
    }
}