import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CountService } from 'src/app/_services/count.service';
import { AuthService, UserService } from 'src/app/_services';
import { EventEmitterService } from 'src/app/_services/even-emitter.service';
import { CountUpdateService } from 'src/app/_services/count-update.service';
import { Project } from 'src/types/Project';
import { Store } from '@ngrx/store';
import { ActiveProjectState } from 'src/app/_state/projects/projects.reducer';
import { clearActiveProject, setActiveProject } from 'src/app/_state/projects/projects.actions';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { formatBytes } from '../../_utils/utils';
import { Role } from 'src/app/_models/role';
import { PartnersService } from 'src/app/_services/partners.service';

@Component({
  selector: 'app-counts',
  templateUrl: './counts.component.html',
  styleUrls: ['./counts.component.scss']
})
export class CountsComponent implements OnInit, OnDestroy, AfterViewInit {
  usersCount: any = 0;
  customersCount: any = 0;
  projectsCount: any = 0;
  partnersCount: number = 0;

  fileCount: number = 0;
  fileSize: string = '0 KB';
  mediaCount: number = 0;
  projectsList: any = [];
  showProjectsList: any = false;
  isAdmin = false;

  public Role = Role;

  activeProject: Project;
  _activeProjectStateListener: Subscription;

  constructor(
    private router: Router,
    private countService: CountService,
    public authService: AuthService,
    private eventEmitterService: EventEmitterService,
    private countUpdateService: CountUpdateService,
    private partnerService: PartnersService,
    private userService: UserService,
    private store: Store<{ activeProject: ActiveProjectState }>,
  ) {
    this.countUpdateService.totalCustomer.subscribe(value => {
      this.customersCount = value.response.CustomerCount;
    })
    this.countUpdateService.showBack.subscribe(value => {
      this.isAdmin = true;
    })
    this.countUpdateService.removeBack.subscribe(value => {
      this.isAdmin = false;
    })
    this.countUpdateService.totalProject.subscribe(value => {
      if (value.response.ProjectCount) {
        this.projectsCount = value.response.ProjectCount;
      }
      if (value.response.Count) {
        this.projectsCount = value.response.Count;
      }
    })
    this.countUpdateService.totalUser.subscribe(value => {
      if (value.response.UserCount) {
        this.usersCount = value.response.UserCount;
      }
      if (value.response.Count) {
        this.usersCount = value.response.Count;
      }
    })
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    // subscribe to changes to counts
    this.countService.counts.subscribe(counts => {
      if(!!counts) {
        this.usersCount = counts.users;
        this.customersCount = counts.customers;
        this.projectsCount = counts.projects;
        this.partnersCount = counts.partners;
      }
    })

    // subscribe to changes to ngrx store active project state
    this._activeProjectStateListener = this.store.select('activeProject').subscribe(({ activeProject }) => {
      this.activeProject = activeProject;

      // if admin or partneradmin is viewing project, set counts
      if(this.authService.hasAccess(Role.PartnerAdmin) && this.activeProject) {
        this.fileCount = this.activeProject.totalFileCount;
        this.fileSize = formatBytes(this.activeProject.totalSize);
        this.mediaCount = this.activeProject.mediaList.length;
      }
    })

    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
        invokeCount.subscribe((name: string) => {
          this.getCounts();
        });
    }

    if(this.authService.isReadOnly) {
      this.getProjectsList();
    } else {
      this.getCounts();
    }
  }

  ngOnDestroy() {
    this._activeProjectStateListener.unsubscribe();
    this.activeProject = undefined;
  }

  /** 
   * Should display user counts instead of admin counts
   * i.e. when admin is viewing a project
   */
  shouldHideAdminCounts(): boolean {
    if(!this.activeProject) return false;
    
    return (
      this.router.url === '/dashboard/file-explorer' ||
      this.router.url === '/dashboard/filesearch'
    );
  }

  goBack() {
    window.history.go(-2);
    this.isAdmin = false;
  }

  getCounts() {
    setTimeout(() => {
      this.countService.getCounts().subscribe(
        ({ counts }) => {
          console.log(counts);
          this.partnersCount = counts.partners || 0;
          this.customersCount = counts.customers || 0;
          this.projectsCount = counts.projects || 0;
          this.usersCount = counts.users || 0;
        },
        ({ error }) => {
          console.error(error);
          console.log('Something went wrong');
        }
      );
    }, 500);
  }

  /** Get user's assigned projects list */
  getProjectsList() {
    this.userService.getAssignedProjects().subscribe(
      ({ projects }) => {
        this.showProjectsList = true;
        this.projectsList = projects;
        this.isAdmin = false;
      },
      (error) => {
        console.log('Something went wrong');
      }
    );
  }

  selectProject(event: any) {
    if (event.target.value === null || event.target.value === 'Select Project') {
      this.store.dispatch(clearActiveProject());
    }
    
    const project: Project = this.projectsList.filter(node => node.name === event.target.value)[0];

    this.fileCount = project.totalFileCount;
    this.fileSize = formatBytes(project.totalSize);
    this.mediaCount = project.mediaList.length;
    console.log(this.mediaCount);

    this.switchProject(project);
  }

  switchProject(project: Project) {
    if(this.activeProject === null) {
      this.router.navigate(['/dashboard/file-explorer/']);
    }
    this.store.dispatch(setActiveProject({ project }));
  }

  showCustomers() {
    this.isAdmin = false;
  }

  showUsers() {
    this.isAdmin = false;
  }
}
