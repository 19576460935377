type InvDataDetails = 
    FileInfo | 
    PathInfo | 
    MediaInfo | 
    BackupSessionInfo | 
    TypeDirectoryInfo

export class InvData {
    constructor(details: InvDataDetails, parent?: InvData) {
        this.contentsFetched = false;
        this.parent = parent || null;
        this.details = details || null;
        this.contents = null;
    }

    fetching: boolean;
    contentsFetched: boolean;
    parent: InvData | null;
    details: InvDataDetails;
    contents: InvData[];

    setContents(contents: InvData[]) {
        this.contentsFetched = true;
        this.contents = contents.map(d => {
            d.parent = this;
            return d;
        })
    }
}

export interface TypeDirectoryInfo {
    id: string;
    name: string;
    mediaType?: MediaInfo['mediaType'] | 'SOURCES';
    type: 'PARENT';
}

export interface FileInfo {
    id: string;

    customerId: string;
    projectId: string;

    parentDirectoryId: string;
    
    media: string;

    backupSessionId: string;

    isEmailFile: string;
    hasFileContent: string;
    S3Link: string;

    Block: string;
    BlockOffset: string;
    FileMark: string;

    name: string;
    fileName: string;
    filePath: string;
    extension: string;

    modifyDate: number;
    accessDate: number;
    createDate: number;
    
    hash: string;
    size: number;
    tagged: boolean;

    fileInformation: string;

    type: 'FILE';
};

export interface PathInfo {
    id: string;
    name: string;
    size: number;
    path: string;
    isRoot: boolean;
    hasFolderChild: boolean;
    hasFileChild: boolean;
    parentDirectoryId: string;
    backupSessionId: string;
    type: 'DIRECTORY';
}

export interface MediaInfo {
    id: string; // same as mediaId
    label: string;
    name: string; // same as label
    information: any;
    type: 'MEDIA';
    mediaType: 'TAPE' | 'SCAN';
}

export interface BackupSessionInfo {
    id: string;
    mediaId: string; // media that this backupsession belongs to

    name: string, // same as label
    label: string;
    backupDate: number;
    restorationConfig: any;
    size: number;
    type: 'BACKUP';
}