import { Component,Input,ChangeDetectorRef   } from '@angular/core';
import { AuthService } from './_services/index';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Invenire';
  username: string;
  loggedIn : false;

  constructor(public AuthService : AuthService, private cdRef:ChangeDetectorRef) { }
  
  ngOnInit() {
    // when user closes app, logout (security purposes)
    window.addEventListener("beforeunload", () => {
      this.AuthService.logout().subscribe();
    })
  }
  
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

}
