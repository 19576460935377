import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AuthService, UIService } from 'src/app/_services';
import { PartnersService } from 'src/app/_services/partners.service';
import { Partner, PartnerPublicInfo } from 'src/types/Partner';

const MAX_FILE_SIZE = 1 * 1000000; // 1 Mb

@Component({
  selector: 'app-partner-scheme',
  templateUrl: './partner-scheme.component.html',
  styleUrls: ['./partner-scheme.component.scss']
})
export class PartnerSchemeComponent implements OnInit {
  private partnerId: string;
  public partnerScheme: PartnerPublicInfo['scheme'] = {
    primary: null,
    primary_dk: null, primary_xdk: null,
    primary_lt: null, primary_xlt: null,
    topbar_left: null, topbar_right: null,
    logo: null,
  };
  schemeForm: FormGroup;

  showColorPicker: boolean[] = new Array(Object.keys(this.partnerScheme).length - 1).fill(false);
  showSubmitButton: boolean;
  showRevertButton: boolean;
  isDone: boolean;

  hexRegExp = new RegExp(/^#(?:[0-9a-fA-F]{3}){1,2}$/);
  public maxFileSizeMb = MAX_FILE_SIZE / 1000000;

  dummyArray = new Array(20).fill(null);
  currentPage: number = 1;
  switchChecked: boolean = false;

  constructor(
    private alertService: AlertService,
    private cd: ChangeDetectorRef,
    public  authService: AuthService,
    private formBuilder: FormBuilder,
    private partnerService: PartnersService,
    private UIService: UIService,
    private location: Location,
  ) { }

  ngOnInit() {
    this.UIService.setTitle('Partner Colors');

    // if accessing page from superadmin partner list
    // get partner from passed route data, otherwise get from current partner
    if(this.authService.isSuperadmin) {
      this.partnerId = history.state.data.partner.id;
      this.partnerScheme = history.state.data.partner.scheme || this.partnerScheme;
    }
    else if(this.authService.isPartnerAdmin) {
      this.partnerId = this.partnerService.partner.id;
      this.partnerScheme = this.partnerService.partner.scheme || this.partnerScheme;
    }

    // build & initialize colors form
    this.schemeForm = this.formBuilder.group({
      primary: [this.partnerScheme.primary, [Validators.required, Validators.pattern(this.hexRegExp)]],
      primary_dk: [this.partnerScheme.primary_dk, Validators.pattern(this.hexRegExp)],
      primary_xdk: [this.partnerScheme.primary_xdk, Validators.pattern(this.hexRegExp)],
      primary_lt: [this.partnerScheme.primary_lt, Validators.pattern(this.hexRegExp)],
      primary_xlt: [this.partnerScheme.primary_xlt, Validators.pattern(this.hexRegExp)],
      topbar_left: [this.partnerScheme.topbar_left, Validators.pattern(this.hexRegExp)],
      topbar_right: [this.partnerScheme.topbar_right, Validators.pattern(this.hexRegExp)],
      logo: this.formBuilder.group({
        name: [null],
        data: [null],
      })
    });

    // when any value changes, make user preview before submit
    this.schemeForm.valueChanges.subscribe(() => {
      this.showSubmitButton = false;
    })
  }

  /** Reset form to partner values */
  resetForm() {
    this.schemeForm.setValue({
      primary: this.partnerScheme.primary || null,
      primary_dk: this.partnerScheme.primary_dk || null,
      primary_xdk: this.partnerScheme.primary_xdk || null,
      primary_lt: this.partnerScheme.primary_lt || null,
      primary_xlt: this.partnerScheme.primary_xlt || null,
      topbar_left: this.partnerScheme.topbar_left || null,
      topbar_right: this.partnerScheme.topbar_right || null,
      logo: { name: null, data: null },
    })
  }

  navigateBack() {
    this.location.back();
  }

  /** Handle change complete on Ngx Color Picker element */
  handleChangeComplete(control: FormControl, color: { hex: string, hsl: any, rgb: any }) {
    control.setValue(color.hex.toUpperCase());
  }

  submitAndSetColorScheme() {
    const colorScheme = { ...this.schemeForm.value };
    console.log(colorScheme.logo);
    colorScheme.logo = colorScheme.logo.data;
    Object.keys(colorScheme).forEach(key => {
      !colorScheme[key] ? delete colorScheme[key] : null;
    });

    console.log(colorScheme);

    this.partnerService.setPartnerScheme(this.partnerId, colorScheme).subscribe(
      () => {
        this.alertService.success('Partner color scheme updated successfully!');
        this.isDone = true;

        // if not changing current partner, revert color scheme
        if(this.partnerId !== this.partnerService.partner.id) this.revertColorScheme(false);
      },
      (err) => {
        console.error(err);
        this.alertService.error('Something went wrong!');
      }
    );
  }

  onFileChange(event: any) {
    const reader = new FileReader();
    const logoControl = this.schemeForm.get('logo');
    logoControl.reset({ name: null, data: null });

    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.schemeForm.patchValue({
          logo: {
            name: file.name,
            data: reader.result,
          }
        });

        // set error if file size is too large
        if(file.size > MAX_FILE_SIZE) {
          this.schemeForm.get('logo').setErrors({ maxFileSizeExceeded: true });
        }

        this.cd.markForCheck();
      };
    }
  }

  openNgxColorPicker(index: number, event: Event) {
    this.showColorPicker = this.showColorPicker.fill(false);
    this.showColorPicker[index] = true;
    event.stopPropagation();
  }

  closeNgxColorPicker(index: number, event: any) {
    this.showColorPicker[index] = false;
  }

  /** temporarily Set CSS variables with new colors without saving to partner */
  previewColorScheme() {
    const colorScheme = { ...this.schemeForm.value };
    colorScheme.logo = colorScheme.logo.data;
    Object.keys(colorScheme).forEach(key => {
      !colorScheme[key] ? delete colorScheme[key] : null;
    });

    this.showSubmitButton = true;
    this.showRevertButton = true;

    this.UIService.previewColorScheme(colorScheme);
  }

  /** Change colors back to partner's current color scheme */
  revertColorScheme(resetForm: boolean = true) {
    this.showSubmitButton = false;
    this.showRevertButton = false;

    resetForm ? this.resetForm() : null;
    this.UIService.reverColorScheme();
  }

}
