// VENDOR
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AlertService, UIService } from 'src/app/_services';
import { ConfirmationDialogService } from 'src/app/_services/cdialog.service';
import { CountService } from 'src/app/_services/count.service';
// APP
import { PartnersService } from 'src/app/_services/partners.service';
import { Partner } from 'src/types/Partner';
import { PageConfig } from '../../../_config/config';

enum PartnerSortMethod { PARTNER_NAME = 'name', EMAIL = 'email', COUNTRY = 'country' }
type SortDirection = 'ASC' | 'DESC';

@Component({
  selector: 'app-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.scss']
})
export class PartnerListComponent implements OnInit {

  constructor(
    private partnerService: PartnersService,
    private countService: CountService,
    private confirmationService: ConfirmationDialogService,
    private alertService: AlertService,
    private UIService: UIService,
  ) { }

  partners: Partner[];
  
  // search ngModel
  nameSearch = {
    value: '',
    valueChanges: new Subject<string>(),
  }

  // pagination settings
  pagination = {
    config: PageConfig,
    limit: {
      value: PageConfig.limitOptions[0].value,
      valueChanges: new Subject<number>(),
    },
    page: PageConfig.startPage,
    totalRecords: undefined,
  }

  // sorting
  sortMethod: PartnerSortMethod = PartnerSortMethod.PARTNER_NAME;
  sortDirection: SortDirection = 'ASC';
  sortMethodTypes = PartnerSortMethod;

  // display settings
  loading: boolean;
  msg: string = undefined;


  ngOnInit() {
    this.UIService.setTitle('List Partners')

    // subscribe to name search input changes and search partners with value
    this.nameSearch.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
      this.getPartners();
    });

    // subscribe to limit value changes and re-fetch if necessary
    // ngModel takes care of setting limit.value for us. no need to set it here
    this.pagination.limit.valueChanges.subscribe(() => {   
      // only refetch if we haven't already fetched all partner records   
      if(!this.pagination.totalRecords || this.pagination.totalRecords < this.pagination.limit) {
        this.getPartners();
      }
    });

    // get list of all partner accounts
    this.getPartners();
  }

  /** Get list of partner accounts */
  getPartners() {
    this.loading = true;

    this.partnerService.getAllPartners(
      this.pagination.limit.value,
      this.pagination.page,
      this.sortMethod,
      this.sortDirection,
      this.nameSearch.value ? this.nameSearch.value : undefined
    ).subscribe(
      ({ partners, count }) => {
        console.log({ partners, count });
        this.partners = partners;
        this.pagination.totalRecords = count;

        this.msg = count === 0 ? 'No customer found' : undefined;
        this.loading = false;
      },
      ({ error }) => {
        this.loading = false;
        this.msg = "Something went wrong";
      }
    )
  }

  /** Delete partner by ID */
  deletePartner(partner: Partner) {
    // confirm user wants to delete partner
    this.confirmationService.confirm('Confirmation', `Do you really want to delete partner "${partner.name}"? This action is permanent.`)
    .then(confirmed => {
      if(confirmed) {
        // delete partner
        this.partnerService.deletePartner(partner.id).subscribe(
          () => {
            this.alertService.success('Partner deletion queued successfully');
            
            // subtract 1 from partners count
            const currentCounts = this.countService.counts.getValue();
            this.countService.counts.next({
              ...currentCounts,
              partners: currentCounts.partners - 1,
            });

            this.getPartners();
          },
          ({ error }) => {
            console.error(error);
            this.alertService.error("Something went wrong!");
          }
        )
      }
    }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  /** Change sort properties and refetch */
  sort(property: PartnerSortMethod) {
    // if property is the same as current, switch sort direction
    if(this.sortMethod === property) {
      this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.sortMethod = property;
      this.sortDirection = 'ASC';
    }

    return this.getPartners()
  };

}
