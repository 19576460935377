import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';	
import { NgxChartsModule } from '@swimlane/ngx-charts'
import { AppRoutingModule } from './app-routing.module';
import { JwtModule } from '@auth0/angular-jwt';
import { TokenInterceptor } from './_interceptor/token';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ColorChromeModule } from 'ngx-color/chrome';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgbModule, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter} from './_filters/dateformatter';

import { ConfirmationDialogComponent } from './_partials/cdialog.component';
import { ConfirmationDialogService } from './_services/cdialog.service';

//Services
import { AuthService, AlertService } from './_services/index';
import { AuthGuard} from './_guards/auth.guard';
import { RoleGuard } from './_guards/role.guard';
import { UIService } from './_services/ui.service';
import {CountUpdateService} from './_services/count-update.service';

// Directives
import { AlertComponent, BannerComponent } from './_partials/index';
// Main component
import { AppComponent } from './app.component';
// Common components
import { LoginComponent }     from './login/login.component';
import { LogoutComponent }    from './logout/logout.component';
import { DashboardComponent } from './dashboard/dashboard.component'
import { HeaderComponent }    from './dashboard/header/header.component';;
import { SidebarComponent }   from './dashboard/sidebar/sidebar.component'
import { CountsComponent }    from './dashboard/counts/counts.component';
import { UserComponent }        from './dashboard/user/user.component';
import { UserformComponent }    from './dashboard/user/userform.component';
import { UserlistComponent }    from './dashboard/user/userlist.component';
import { ProjectComponent }     from './dashboard/project/project.component';
import { ProjectformComponent } from './dashboard/project/projectform.component';
import { ProjectlistComponent } from './dashboard/project/projectlist.component';
import { CustomerComponent }    from './dashboard/customer/customer.component';
import { CustomerformComponent }from './dashboard/customer/customerform.component';
import { CustomerlistComponent }from './dashboard/customer/customerlist.component';
import { SearchformComponent }  from './dashboard/search/searchform.component';
import { AdvancesearchComponent } from './dashboard/search//advancesearch.component';
import { SearchresultComponent } from './dashboard/search//searchresult.component';
import { SearchComponent }      from './dashboard/search/search.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { DialogComponent } from './_partials/dialog.component';
import { DialogService } from './_services/dialog.service';
import { MediaComponent } from './dashboard/media/media.component';
import { TreeviewComponent, TreeListComponent} from './dashboard/media/treeview/treeview.component';
import { BackupComponent } from './dashboard/media/backup/backup.component';
import { OrderByPipe } from './_filters/tablesort';
import { FileExplorerComponent } from './file-explorer/file-explorer.component';
import { FileExplorerPageComponent } from './dashboard/file-explorer-page/file-explorer-page.component';
import { ExplorerNavRowComponent } from './file-explorer/explorer-nav-row/explorer-nav-row.component';
import { ExplorerContentsComponent } from './file-explorer/explorer-contents/explorer-contents.component';
import { BreadcrumbPanelComponent } from './file-explorer/breadcrumb-panel/breadcrumb-panel.component';
import { ToolbarComponent } from './file-explorer/toolbar/toolbar.component';
import { InfoPanelComponent } from './file-explorer/info-panel/info-panel.component';
import { PartnerListComponent } from './dashboard/partners/list/partner-list.component';
import { PartnerFormComponent } from './dashboard/partners/form/partner-form.component';
import { PartnerViewComponent } from './dashboard/partners/view/partner-view.component';
import { PartnerSchemeComponent } from './dashboard/partners/scheme/partner-scheme.component';
// OTHER
import { MediasearchComponent } from './dashboard/mediasearch/mediasearch.component';
import { activeProjectReducer } from './_state/projects/projects.reducer';
import { SearchGuidelinesComponent } from './dashboard/search/search-guidelines/search-guidelines.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ResizeColumnDirective } from './_directives/resize-column.directive';
import { appInitializer } from './app.initializer';
import { ErrorInterceptor } from './_interceptor/error.interceptor';
import { ClickOutsideDirective } from './_directives/click-outside.directive';
import { SearchAnalyticsComponent } from './dashboard/search/analytics/analytics.component';
import { FormatBytesPipe } from './_pipes/format-bytes.pipe';
import { FormatTRACSDatePipe } from './_pipes/format-tracs-date.pipe';



export function getCookieValue(name: string) {
  let ca: Array<string> = document.cookie.split(';');
  let caLen: number = ca.length;
  let cookieName = `${name}=`;
  let c: string;
  for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
          return c.substring(cookieName.length, c.length);
      }
  }
  return '';
}
export function tokenGetter() {
  return getCookieValue('access_token');
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AlertComponent,
    SidebarComponent,
    LoginComponent,
    BannerComponent,
    DashboardComponent,
    UserComponent,
    CountsComponent,
    CustomerComponent,
    ProjectComponent,
    LogoutComponent,
    UserlistComponent,
    ProjectComponent,
    UserformComponent,
    ProjectformComponent,
    ProjectlistComponent,
    CustomerformComponent,
    CustomerlistComponent,
    SearchformComponent,
    AdvancesearchComponent,
    SearchresultComponent,
    SearchComponent,
    ForgotpasswordComponent,
    NotfoundComponent,
    ConfirmationDialogComponent,
    DialogComponent,
    MediaComponent,
    TreeviewComponent,
    BackupComponent,
    TreeListComponent,
    OrderByPipe,
    MediasearchComponent,
    MediasearchComponent,
    FileExplorerComponent,
    FileExplorerPageComponent,
    ExplorerNavRowComponent,
    ExplorerContentsComponent,
    BreadcrumbPanelComponent,
    ToolbarComponent,
    InfoPanelComponent,
    SearchGuidelinesComponent,
    ResizeColumnDirective,
    PartnerListComponent,
    PartnerFormComponent,
    PartnerViewComponent,
    PartnerSchemeComponent,
    ClickOutsideDirective,
    SearchAnalyticsComponent,
    FormatBytesPipe,
    FormatTRACSDatePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule, 
    ReactiveFormsModule,
    AngularMultiSelectModule,
    NgbModule,
    NgxChartsModule,
    ColorChromeModule,
    StoreModule.forRoot({
      activeProject: activeProjectReducer,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['http://localhost:4200'],
        blacklistedRoutes: ['http://localhost:4200/dashboard'] 
      }
    }),
    NgCircleProgressModule.forRoot({
      showBackground: false,
      showImage: false,
      showInnerStroke: false,
      showSubtitle: false,
      showTitle: false,
      showUnits: false,
    }),
    NgxPaginationModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService] },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
    AlertService, AuthService, AuthGuard, RoleGuard, UIService, Title, ConfirmationDialogService, DialogService, CountUpdateService],
  bootstrap: [AppComponent],
  entryComponents: [ ConfirmationDialogComponent ]
})
export class AppModule { }
