import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { API_ENDPOINT } from './../_config/config';

@Injectable({
    providedIn: 'root'
  })
export class MediaService {
    constructor(private http: HttpClient) { }

    getMediaTypes(id:any, pid:any) {
        return this.http.get(`${API_ENDPOINT}/api/mediatype/mediatypelist?id=`+id + '&projectId='+ pid);
    }
    getMediaFiles(id:any, pid:any, obj:any) {
        return this.http.post(`${API_ENDPOINT}/api/mediatype/searchmedia?id=`+id + '&projectId='+ pid, obj);
    }
}