import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder , FormGroup, Validators } from '@angular/forms';
import { AlertService, UserService, CustomerService, ProjectService, UIService, AuthService } from 'src/app/_services';
import { CountUpdateService } from '../../_services/count-update.service';
import { ActivatedRoute, Router } from '@angular/router';
import { setActiveProject } from 'src/app/_state/projects/projects.actions';
import { Project } from 'src/types/Project';
import { Store } from '@ngrx/store';
import { User } from 'src/types/User';
import { CountService } from 'src/app/_services/count.service';
import { Role } from 'src/app/_models/role';

export type ProjectFormViewType = 'edit' | 'assign' | 'new' | 'view';

@Component({
  selector: 'app-projectform',
  templateUrl: './projectform.component.html',
  styleUrls: ['./projectform.component.scss']
})
export class ProjectformComponent implements OnInit {
  constructor(
    private customerService : CustomerService,
    private userService :UserService,
    private formBuilder : FormBuilder,
    private alert : AlertService,
    private authService: AuthService,
    private projectService : ProjectService,
    private uiservice : UIService,
    private countService: CountService,
    private route :ActivatedRoute,
    private location: Location,
    private router: Router,
    private store: Store
  ) { }
  // Page element setting
  pageTitle : string;
  projectview: ProjectFormViewType;
  buttonTitle : string;
  
  // Form settings and configuration
  projectForm: FormGroup;
  submitted = false;
  formData = {};
  urlParams : object;
  loading: any;
  msg: string;

  // Multi select dropdown
  selectedItems = [];
  dropdownSettings = {};
  dropdownList = [];
  userIdsArray = [];

  // Client information
  customerName:string;
  projectId :any;
  customers:any;
  users: any;
  project : any = {};
  assignedUsers : any = {};

  showReturnButton: boolean = false;
  isCaching: string | boolean = false;

  ngOnInit() {
    // build page based on view type passed from previous page
    this.projectview = this.route.snapshot.paramMap.get('type') as ProjectFormViewType;

    // if view type is to 'view' project, set existing values
    if(this.projectview === 'view') {
      this.project = history.state.data.project;
      this.assignedUsers = this.project.users;
      this.pageTitle = `Project: ${this.project.name}`;
      this.uiservice.setTitle('View Project');
      return;
    }

    switch(this.projectview) {
      case 'edit':
        this.pageTitle = 'Edit Project';
        this.buttonTitle = 'update project';
        this.uiservice.setTitle('Edit Project');
        break;
      case 'assign':
        this.pageTitle = 'Assign Users';
        this.buttonTitle = 'assign users';
        this.uiservice.setTitle('Assign Users');
        break;
      default: // 'new' view
        this.getCustomers();
        this.projectview = 'new';
        this.pageTitle = 'Create Project';
        this.buttonTitle = 'create project';
        this.uiservice.setTitle('Create Project');
    }
    
    // build and, if necessary, populate form based on view type
    this.buildForm();
    this.projectview === 'edit' || this.projectview === 'assign' ? this.setExistingFormValues() : null;
  }

  /** Build user form based on view type */
  private buildForm() {
    // if user is customer admin, set customer value
    let initialCustomerValue: any = '';
    if(this.authService.isCustomerAdmin) {
      this.customers = [this.customerService.customer];
      initialCustomerValue = { value: this.customerService.customer.id, disabled: true };
    }

    if(this.projectview === 'assign') {
      this.projectForm = this.formBuilder.group({
        users: [''],
      });
    } else {
      this.projectForm = this.formBuilder.group({
        name: ['', Validators.required],
        customer: [initialCustomerValue, Validators.required],
        status: ['', Validators.required],
        description: ['']
      });
    }
  }

  /** If in edit or assign mode, set existing user form values */
  private setExistingFormValues() {
    const project: Project = history.state.data.project;
    this.projectId = project.id;

    if(this.projectview === 'assign') {
      // fetch all users for the project's customer
      this.getCustomerUsers(project.customer.id);

      // if in assign mode, set already assigned project values
      if(this.projectview === 'assign') {
        this.projectForm.setValue({
          users: project.users.map((user: User) => ({ id: user.id, itemName: user.email })),
        });
      }
    }

    // if in edit mode, set existing form values
    if(this.projectview === 'edit') {
      this.customers = [project.customer];

      this.projectForm.setValue({
        name: project.name,
        customer: project.customer.id,
        status: project.status,
        description: project.description || '',
      });

      this.projectForm.get('customer').disable();
    }
  }

  /** Get all customers */
  getCustomers() {
    // if user is customer admin, no need to fetch customers
    if(this.authService.isCustomerAdmin) {
      this.customers = [this.customerService.customer];
      return;
    }

    this.loading= true;
    this.msg = "Loading...";
    this.customerService.getCustomers(100).subscribe(
      ({ customers }) => {
          this.loading = false;
          this.customers = customers;
      },
      (error) => {
          this.msg = "Something went wrong";
      }
    );
  }

  /** Map users to select options */
  private getUserCallback() {
    // map users to the dropdown list
    this.dropdownList = this.users
      .filter(x => x.role === Role.ReadOnly)
      .map(user => ({ id: user.id, itemName: user.email }));
    // set dropdown settings
    this.dropdownSettings = { 
      singleSelection: false, 
      text:"Select Users*",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };    
  }
  
  /** Navigate to previous page */
  navigateBack() {
    this.location.back();
  }
  
  selectCustomer(args:any){ 
    this.customerName = args.target.options[args.target.selectedIndex].text; 
  }

  viewProject(project: Project) {
    this.store.dispatch(setActiveProject({ project }));
    this.router.navigate(['/dashboard/file-explorer']);
  }

  /** On form Submission */
  onSubmit() {
    this.submitted = true;
 
    // stop the process here if form is invalid
    if (this.projectForm.invalid) {
      alert('Form Invalid. Please fix the highlighted issues');
      return false;
    }

    if(this.projectview === 'edit')    return this.updateProject();
    if(this.projectview === 'assign')  return this.assignUsers();
    if(this.projectview === 'new')     return this.createProject();  
  }

  /** Update users list for project */
  assignUsers() { 
    const userList = this.projectForm.value.users.map(user => user.id);
    this.projectService.assignUsers(userList, this.projectId).subscribe(
      () => {
        this.alert.success(`Successfully assigned project to users`);
        this.submitted = false;
        this.projectForm.reset();
        this.showReturnButton = true;
      },
      (err) => {
        console.error(err);
        this.submitted = false;
        this.alert.error('Something went wrong!');
      }
    )
  }

  /** Send update project request */
  updateProject(){ 
    // format submission data
    const formData = this.projectForm.getRawValue();
    Object.keys(formData).forEach(key => {
      !formData[key] ? delete formData[key] : null;
    });

    this.projectService.update(this.projectId, formData).subscribe(
      () => {
        this.alert.success('Project successfully updated');
        this.submitted = false;
        this.projectForm.reset();
        this.showReturnButton = true;
      },
      (err) => {
        console.error(err);
        this.submitted = false;
        this.alert.error('Something went wrong');
      }
    )
  }
  
  /** Create project in API/Database */
  createProject() {
    // format submission data
    const formData = this.projectForm.getRawValue();
    Object.keys(formData).forEach(key => {
      !formData[key] ? delete formData[key] : null;
    });

    this.projectService.create(formData).subscribe(
      ({ project }) => {
        this.alert.success(`Project successfully created: ${project.s3Path}`);
        this.submitted = false;
        this.projectForm.reset();
        this.showReturnButton = true;
        
        // add 1 to projects count
        const currentCounts = this.countService.counts.getValue();
        this.countService.counts.next({
          ...currentCounts,
          projects: currentCounts.projects + 1,
        });
      },
      (err) => {
        console.error(err);
        this.submitted = false;
        this.alert.error('Something went wrong');
      }
    )
  }

  /** Get all users for specified customer */
  private getCustomerUsers(customerId: string) {
    this.userService.getUsers(25).subscribe(
      ({ users }) => {
        this.users = users;
        this.loading = false;
        this.getUserCallback();
      },
      (err) => {
        console.error(err);
        this.loading = false;
        this.msg = "Something went wrong";
      }
    )
  }

  /** Force caching of project analytics */
  forceProjectCache(projectId: string) {
    this.isCaching = true;
    this.projectService.cacheProjectAnalytics(projectId).subscribe(
      () => {
        this.isCaching = 'done';
        alert('Project Analytics Successfully Cached!');
      },
      (err) => {
        this.isCaching = false;
        alert('Error caching project analytics')
      }
    );
  }
}
