import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators'
import { Router } from '@angular/router';

import { AuthService } from '../_services/auth.service';
import { environment } from 'src/environments/environment';

const ROUTE_EXCLUDE_LIST = [
  `${environment.api_endpoint}/auth/refresh-token`,
  `${environment.api_endpoint}/auth/login`,
  `${environment.api_endpoint}/auth/logout`
];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // if on exclude list, just return original request
    if(ROUTE_EXCLUDE_LIST.includes(request.url)) {
      return next.handle(request);
    }

    // otherwise, detect a 401, and attempt refreshing access token
    return next.handle(request).pipe(catchError(err => {
      if(err.status === 401 && this.authService.accessToken) {
        // if request was unathorized
        return this.handle401(request, next);
      } else {
        return throwError(err);
      }
    }));
  }

  /** silently handle 401 (Unathorized) error if access token expires */
  private handle401(request: HttpRequest<unknown>, next: HttpHandler) {
    return this.authService.refreshAccessToken().pipe(
      switchMap(({ access_token }) => {
        this.authService.setAccessToken(access_token);
        return next.handle(request);
      }),
      catchError(err => {
        if(err instanceof HttpErrorResponse && err.status === 401) {
          this.authService.setAccessToken(null);
          this.router.navigate(['login']);
          return;
        }
        
        alert('Something went wrong...')
        return throwError('Something went wrong...');
      })
    );
  }
}
