import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard }       from './_guards/auth.guard';
import { RoleGuard }       from './_guards/role.guard';

import { LoginComponent }       from './login/login.component';
import { LogoutComponent }      from './logout/logout.component';
import { DashboardComponent }   from './dashboard/dashboard.component';
import { UserlistComponent }    from './dashboard/user/userlist.component';
import { UserformComponent }    from './dashboard/user/userform.component';
import { CustomerlistComponent }from './dashboard/customer/customerlist.component';
import { CustomerformComponent }from './dashboard/customer/customerform.component';
import { ProjectformComponent } from './dashboard/project/projectform.component';
import { ProjectlistComponent } from './dashboard/project/projectlist.component';
import { SearchComponent } from './dashboard/search/search.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { Role } from './_models/role';
import { NotfoundComponent } from './notfound/notfound.component';
import { MediaComponent } from './dashboard/media/media.component';
import { ProjectComponent } from './dashboard/project/project.component';
import { MediasearchComponent } from './dashboard/mediasearch/mediasearch.component';
import { BackupComponent } from './dashboard/media/backup/backup.component';
import { FileExplorerPageComponent } from './dashboard/file-explorer-page/file-explorer-page.component';
import { PartnerListComponent } from './dashboard/partners/list/partner-list.component';
import { PartnerFormComponent } from './dashboard/partners/form/partner-form.component';
import { PartnerViewComponent } from './dashboard/partners/view/partner-view.component';
import { PartnerSchemeComponent } from './dashboard/partners/scheme/partner-scheme.component';


const routes: Routes = [ 
  { path: 'login',            component: LoginComponent },
  { path: '404',              component: NotfoundComponent },
  { path: 'logout',           component: LogoutComponent },
  { path: 'forgot-password',  component: ForgotpasswordComponent},
  { path: 'dashboard',        component: DashboardComponent, canActivate: [AuthGuard] },
  
  // user paths
  { path: 'dashboard/user/new',       component: UserformComponent, canActivate: [AuthGuard,RoleGuard], data:  { role: Role.CustomerAdmin }},
  { path: 'dashboard/user/:type/:id', component: UserformComponent, canActivate: [AuthGuard,RoleGuard], data:  { role: Role.CustomerAdmin }},
  { path: 'dashboard/user/list',      component: UserlistComponent, canActivate: [AuthGuard,RoleGuard], data:  { role: Role.CustomerAdmin }},

  // project paths
  { path: 'dashboard/project/new',        component: ProjectformComponent, canActivate: [AuthGuard,RoleGuard], data: { role: Role.CustomerAdmin }},
  { path: 'dashboard/project/:type/:id',  component: ProjectformComponent, canActivate: [AuthGuard,RoleGuard], data: { role: Role.CustomerAdmin }},
  { path: 'dashboard/project/list',       component: ProjectlistComponent, canActivate: [AuthGuard,RoleGuard], data: { role: Role.CustomerAdmin }},
  { path: 'dashboard/project/view/:id',   component: ProjectComponent,     canActivate: [AuthGuard,RoleGuard], data: { role: Role.CustomerAdmin }},
  
  // customer paths
  { path: 'dashboard/customer/new',       component: CustomerformComponent, canActivate: [AuthGuard,RoleGuard], data: { role: Role.PartnerAdmin }},
  { path: 'dashboard/customer/edit/:id',  component: CustomerformComponent, canActivate: [AuthGuard,RoleGuard], data: { role: Role.PartnerAdmin }},
  { path: 'dashboard/customer/list',      component: CustomerlistComponent, canActivate: [AuthGuard,RoleGuard], data: { role: Role.PartnerAdmin }},

  // partners paths
  { path: 'dashboard/partner/new',        component: PartnerFormComponent, canActivate: [AuthGuard, RoleGuard], data: { role: Role.SuperAdmin }},
  { path: 'dashboard/partner/edit/:id',   component: PartnerFormComponent, canActivate: [AuthGuard, RoleGuard], data: { role: Role.SuperAdmin }},
  { path: 'dashboard/partner/view/:id',   component: PartnerViewComponent, canActivate: [AuthGuard, RoleGuard], data: { role: Role.SuperAdmin }},
  { path: 'dashboard/partner/list',       component: PartnerListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: Role.SuperAdmin }},
  { path: 'dashboard/partner/:id/scheme', component: PartnerSchemeComponent, canActivate: [AuthGuard, RoleGuard], data: { role: Role.PartnerAdmin }},
  
  { path: 'dashboard/media', component: MediaComponent, canActivate: [AuthGuard,RoleGuard], data: { role: Role.ReadOnly }},

  { path: 'dashboard/filesearch',             component: SearchComponent, canActivate: [AuthGuard , RoleGuard], data: { role: Role.ReadOnly }},
  { path: 'dashboard/mediasearch/:id/:pid',   component: MediasearchComponent, canActivate: [AuthGuard , RoleGuard], data: { role: Role.ReadOnly }},
  { path: 'dashboard/mediabackup/:id/:name',  component: BackupComponent, canActivate: [AuthGuard , RoleGuard], data: { role: Role.ReadOnly }},
  { path: 'dashboard/file-explorer',          component: FileExplorerPageComponent, canActivate: [AuthGuard, RoleGuard], data: { role: Role.ReadOnly }},
  { path: '',  redirectTo: '/login',  pathMatch: 'prefix'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
