import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { ProjectService } from 'src/app/_services/project.service';
import { AlertService, AuthService, UIService } from 'src/app/_services';
import { ConfirmationDialogService } from 'src/app/_services/cdialog.service';
import { PageConfig } from './../../_config/config';
import { Project } from 'src/types/Project';
import { ActiveProjectState } from 'src/app/_state/projects/projects.reducer';
import { setActiveProject } from 'src/app/_state/projects/projects.actions';
import { CountService } from 'src/app/_services/count.service';

enum ProjectSortMethod { 
  NAME = 'name',
  CUSTOMER_NAME = 'customer',
  PARTNER_NAME = 'partner',
  CREATION_DATE = 'createdAt'
};
type SortDirection = 'ASC' | 'DESC';

@Component({
  selector: 'app-projectlist',
  templateUrl: './projectlist.component.html',
  styleUrls: ['./projectlist.component.scss']
})
export class ProjectlistComponent implements OnInit {

  constructor(
    private confirmationDialogService : ConfirmationDialogService,
    private projectService : ProjectService,
    private formBuilder: FormBuilder,
    private countService: CountService,
    private alert: AlertService,
    public authService: AuthService,
    private uiservice : UIService,
    private store: Store<{ activeProject: ActiveProjectState }>,
    private router: Router,
  ) { }

  projects: any[];
  loading : any;
  msg : any;
  projectSearchForm: FormGroup;
  recordLimitForm: FormGroup;
  submitted = false;
  assignedUsers : any;
  currentId: any;
  currentProjectId : any;
  filter: any;
  
  // pagination declaration
  pagination: Record<string, any>;
  sortKeyArray = [{ id: '', sortkey: '' }];
  currentPage : number;

  // Sorting
  sortMethod: ProjectSortMethod = ProjectSortMethod.NAME;
  sortDirection: SortDirection = 'ASC';
  public sortMethodTypes = ProjectSortMethod;

  ngOnInit() {
    // pagination configuration
    this.pagination = {};
    this.pagination['options'] = PageConfig.limitOptions;
    this.pagination['limit'] = PageConfig.limit;
    this.pagination['show'] = PageConfig.enabled;
    this.currentPage = PageConfig.startPage;

    // build forms
    this.projectSearchForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
    
    this.recordLimitForm = this.formBuilder.group({
      recordlimit: [PageConfig.limitOptions[0].value]
    });

    this.uiservice.setTitle('List Project'); 
    this.getProjects();

    // after user has not typed for 500 ms in project name search, run search again
    this.projectSearchForm.valueChanges.pipe(debounceTime(500)).subscribe(({ name }) => {
      this.getProjects();
    });
  }

  /** Set active project and switch to file explorer view */
  viewProject(project: Project) {
    this.store.dispatch(setActiveProject({ project }));
    this.router.navigate(['/dashboard/file-explorer']);
  }

  /** Generate Pagination navigator */
  pageConfig(totalCount: number) {
    this.pagination['total'] = totalCount;
    this.pagination['pages'] = this.uiservice.roundUp(totalCount/this.pagination['limit']);
    
    // only show pagination if total count > current limit
    this.pagination.show = this.pagination.total > this.pagination.limit ? true : false;
  }
  
  /** go to previous or next page and get customers with new offset */
  paginate(mode: 'forward' | 'previous'){
    if((this.currentPage === this.pagination['pages'] && mode === 'forward') || this.currentPage === 0 && mode === 'previous'){
      return false;
    }

    mode === 'forward' ? this.currentPage += 1 : this.currentPage -= 1;
    this.getProjects();
  }

  /** Get all Projects */
  getProjects() {
    this.loading = true;

    this.projectService.getProjects(
      this.pagination.limit,
      this.currentPage,
      this.sortMethod,
      this.sortDirection,
      this.projectSearchForm.value.name || undefined,
    ).subscribe(
      ({ projects, count }) => {
        this.projects = projects;
        console.log(projects[0]);
        this.msg = count === 0 ? 'No projects found' : undefined;

        this.pageConfig(count);
        this.loading = false;
      },
      (err) => {
        console.error(err);
        this.loading = false;
        this.msg = 'Something went wrong';
      }
    )
  }

  /** Delete project */
  deleteProject(project: Project) { 
    // confirm user wants to delete user
    this.confirmationDialogService.confirm('Confirmation', `Do you really want to delete project "${project.name}"?`)
      .then(confirmed => {
        if(confirmed) {
          // delete project
          this.projectService.delete(project.id).subscribe(
            () => {
              this.alert.success('Project deletion queued successfully');
              this.getProjects();

              // subtract 1 from projects count
              const currentCounts = this.countService.counts.getValue();
              this.countService.counts.next({
                ...currentCounts,
                projects: currentCounts.projects - 1,
              });
            },
            ({ error }) => {
              console.error(error);
              this.alert.error('Something went wrong!');
            }
          )
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  updateRecords(limit:number){
    this.currentPage = 0;
    this.pagination['limit'] = limit;
    this.sortKeyArray = [{ id: '', sortkey: '' }];
    this.getProjects();
  }

  sort(property: ProjectSortMethod) {
    // if property is the same as current, switch sort direction
    if(this.sortMethod === property) {
      this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.sortMethod = property;
      this.sortDirection = 'ASC';
    }

    return this.getProjects()
  };

  /** Toggle assign users popover open and close */
  togglePopover(popover: NgbPopover, project: Project) {
    popover.isOpen() ? popover.close() : popover.open({ project });
  }
}
