import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_ENDPOINT } from '../_config/config';
import { Partner, PartnerDto, PartnerPublicInfo } from 'src/types/Partner';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

const LS_PARTNER_KEY = 'CP';

@Injectable({
  providedIn: 'root'
})
export class PartnersService {
  public partnerSubject: BehaviorSubject<PartnerPublicInfo>;
  public partnerList: PartnerPublicInfo[];

  constructor(private http: HttpClient) {
    this.partnerSubject = new BehaviorSubject(undefined);
  }

  get partner() { return this.partnerSubject.value; }
  
  set partner(partner: PartnerPublicInfo) {
    this.partnerSubject.next(partner);
  }

  /** Get all partner accounts */
  getAllPartners(limit: number, pageNumber: number = 1, sortAttr: string = 'name', sortDirection: 'ASC' | 'DESC' = 'ASC', nameSearch?: string) {
    const offset = limit * ( pageNumber - 1);

    let params = new HttpParams();
    params = params.append('limit', limit.toString());
    params = params.append('offset', offset.toString());
    params = params.append('sortAttr', sortAttr);
    params = params.append('sortDirection', sortDirection);
    params = nameSearch ? params.append('name', encodeURIComponent(nameSearch)) : params;

    return this.http.get<{ statusCode: number, partners: any[], count: number }>(`${API_ENDPOINT}/partners`, { params });
  }

  /** Get all public information for all partners */
  getAllPartnersPublicInfo() {
    return this.http.get<{ partners: PartnerPublicInfo[] }>(`${API_ENDPOINT}/partners/all/public`).pipe(
      map(resp => {
        this.partnerList = resp.partners;
        return resp;
      })
    )
  }

  /** Get partner by partner's unique uri */
  getPartnerByUri(partnerUri: string) {
    return this.http.get<{ partner: PartnerPublicInfo }>(`${API_ENDPOINT}/partners/${partnerUri}/public`)
  }

  /** Send create partner request to API */
  createPartner(partnerDto: PartnerDto) {
    return this.http.post<{ partner: Partner }>(`${API_ENDPOINT}/partners`, partnerDto);
  }

  /** Send update partner request to API */
  updatePartner(partnerId: string, partnerDto: PartnerDto) {
    return this.http.patch(`${API_ENDPOINT}/partners/${partnerId}`, partnerDto);
  }

  /** Set partner scheme and save to DB */
  setPartnerScheme(partnerId: string, scheme: Partner['scheme']) {
    return this.http.patch<{ statusCode: number, partner: Partner }>(`${API_ENDPOINT}/partners/${partnerId}/scheme`, { ...scheme }).pipe(
      map(resp => {
        const { partner } = resp;
        // if changing current partner scheme, update this.partner
        if(partnerId === this.partner.id) {
          this.partner = {
            id: partner.id,
            name: partner.name,
            uri: partner.uri,
            scheme: partner.scheme,
          };
        }

        return resp;
      })
    )
  }

  /** Make delete request to delete partner */
  deletePartner(partnerId: string) {
    return this.http.delete<{ message: string }>(`${API_ENDPOINT}/partners/${partnerId}`)
  }

}
