import { Component, OnInit } from '@angular/core';
import { UIService } from '../_services/ui.service';
import { AuthService } from '../_services';
import { Role } from '../_models/role';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  tableTitle = 'Recent Customers';
  public Role = Role;

  constructor(
    private uiservice:UIService,
    public authService: AuthService
    ) { }

  ngOnInit() {
    this.uiservice.setTitle('Dashboard');
  }

}
