import { Injectable, OnInit } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { Partner } from 'src/types/Partner';
import { PartnersService } from './partners.service';

const defaultScheme: Partner['scheme'] = {
    primary: '#5F2292',
    topbar_left: '#FFF',
    logo: '/assets/images/logowhite.png',
}

@Injectable()
export class UIService {
    scheme: Partner['scheme'];
    // behavior subject for logo to display
    logoSubject = new BehaviorSubject<string>(null);

    partnerListener: any;
  
    constructor(
        private titleService: Title,
        private partnerService: PartnersService,
    ) {
        this.partnerListener = this.partnerService.partnerSubject.subscribe(partner => {
            const currentPartner = partner;
            this.scheme = currentPartner && currentPartner.scheme ?
                currentPartner.scheme : defaultScheme;

            this.logoSubject.next(this.scheme.logo);
            this.setSchemeCSS(this.scheme);
        });
    }

    overlay(arg:String){
        let overlayEle = document.getElementById('overlay');
        let usecase = (arg == 'hide') ? 'none' : 'block';
        overlayEle.style.display = usecase;
    }

    public setTitle( newTitle: string) {
        this.titleService.setTitle( newTitle + ' - Invenire');
    }

    public roundUp(num:number, precision:number=0) {
        precision = Math.pow(10, precision)
        return Math.ceil(num * precision) / precision
    }

    public getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    public deleteCookie(name:string) {
        this.setCookie(name, '', -365);
    }

    public setCookie(name: string, value: string, expireDays: number, path: string = '/') {
        let d:Date = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        let expires:string = `expires=${d.toUTCString()}`;
        let cpath:string = path ? `; path=${path}` : '';
        document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }

    /** Preview color scheme */
    public previewColorScheme(colorScheme: Partner['scheme']) {
        this.setSchemeCSS(colorScheme);
    }

    /** Revert color scheme back to partner's color scheme */
    public reverColorScheme() {
        this.setSchemeCSS(this.scheme);
    }

    /** Set CSS variables from partner scheme */
    private setSchemeCSS(colorScheme: Partner['scheme']) {
        const bodyElemStyle = document.querySelector('body').style;
        bodyElemStyle.setProperty('--scheme-primary', colorScheme.primary);

        bodyElemStyle.setProperty('--scheme-primary-dk', colorScheme.primary_dk || this.lightenDarkenColor(colorScheme.primary, -50));
        bodyElemStyle.setProperty('--scheme-primary-xdk', colorScheme.primary_xdk || this.lightenDarkenColor(colorScheme.primary, -80));

        bodyElemStyle.setProperty('--scheme-primary-lt', colorScheme.primary_lt || this.lightenDarkenColor(colorScheme.primary, 50));
        bodyElemStyle.setProperty('--scheme-primary-xlt', colorScheme.primary_xlt || this.lightenDarkenColor(colorScheme.primary, 80));

        bodyElemStyle.setProperty('--scheme-topbar-right', colorScheme.topbar_right || colorScheme.primary);
        bodyElemStyle.setProperty('--scheme-topbar-left', colorScheme.topbar_left || '#FFF');
        
        if(colorScheme.logo) {
            this.logoSubject.next(colorScheme.logo);
        }
    }

    /** @see https://css-tricks.com/snippets/javascript/lighten-darken-color/ */
    private lightenDarkenColor(col: string, amt: number) {

        var usePound = false;
      
        if (col[0] == "#") {
            col = col.slice(1);
            usePound = true;
        }
     
        var num = parseInt(col,16);
     
        var r = (num >> 16) + amt;
     
        if (r > 255) r = 255;
        else if  (r < 0) r = 0;
     
        var b = ((num >> 8) & 0x00FF) + amt;
     
        if (b > 255) b = 255;
        else if  (b < 0) b = 0;
     
        var g = (num & 0x0000FF) + amt;
     
        if (g > 255) g = 255;
        else if (g < 0) g = 0;
     
        return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
    }

    /** Convert hex color to HSL */
    private toHSL(hex: string) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    
        var r = parseInt(result[1], 16);
        var g = parseInt(result[2], 16);
        var b = parseInt(result[3], 16);
    
        r /= 255, g /= 255, b /= 255;
        var max = Math.max(r, g, b), min = Math.min(r, g, b);
        var h, s, l = (max + min) / 2;
    
        if(max == min){
            h = s = 0; // achromatic
        } else {
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch(max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }
    
        s = s*100;
        s = Math.round(s);
        l = l*100;
        l = Math.round(l);
        h = Math.round(360*h);
    
        return { h, s, l };
    }
}
