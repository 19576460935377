import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_ENDPOINT } from '../_config/config';
import { Customer } from '../../types/Customer';
import { PartnersService } from './partners.service';
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerService { 
    public customerSubject = new BehaviorSubject<Customer>(undefined);

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private partnerService: PartnersService,
    ) { }

    get customer() { return this.customerSubject.value; }
    set customer(customer: Customer) {
        this.customerSubject.next(customer);
    }

    getCustomers(limit: number, pageNumber: number = 1, sortAttr: string = 'name', sortDirection: 'ASC' | 'DESC' = 'ASC', nameSearch?: string) {
        const offset = limit * ( pageNumber - 1);

        let params = new HttpParams();
        params = params.append('limit', limit.toString());
        params = params.append('offset', offset.toString());
        params = params.append('sortAttr', sortAttr);
        params = params.append('sortDirection', sortDirection);
        params = nameSearch ? params.append('name', nameSearch) : params;

        if(this.authService.isSuperadmin)   return this.getAllCustomers(params);
        if(this.authService.isPartnerAdmin) return this.getCustomersByPartner(params);
    }

    /** Get all customers in database */
    private getAllCustomers(params: HttpParams) {
        return this.http.get<{ statusCode: number, customers: any[], count: number }>(`${API_ENDPOINT}/customers`, { params });
    }

    /** Get all customers that belong to a partner */
    private getCustomersByPartner(params: HttpParams) {
        const partnerId = this.partnerService.partner.id;
        return this.http.get<{ statusCode: Number, customers: any[], count: number }>
            (`${API_ENDPOINT}/partners/${partnerId}/customers`, { params });
    }

    /** Get current customer that customer admin is signed in with */
    getCurrentCustomer() {
        return this.http.get<{ statusCode: number, customer: Customer }>(`${API_ENDPOINT}/customers/current`).pipe(
            map(resp => {
                this.customer = resp.customer;
                return resp;
            })
        )
    }

    /** send Create Customer request to API */
    create(customer: Customer) {
        return this.http.post<{ customer: Customer }>(`${API_ENDPOINT}/customers`, { ...customer });
    }

    /** Send update customer request to API */
    update(customer: Customer) {
        return this.http.patch<{ customer: Customer }>(`${API_ENDPOINT}/customers`, { ...customer });
    }

    /** Send delete customer request to API */
    delete(customerId: string) {
        return this.http.delete<{ message: string }>(`${API_ENDPOINT}/customers/${customerId}`);
    }
}
