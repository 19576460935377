import { Pipe, PipeTransform } from '@angular/core';

@Pipe({  name: 'orderBy' })
export class OrderByPipe implements PipeTransform {

  transform(records: Array<any>, args?: any): any {
    records.map((record) =>{
      if(record.customerInformation){
        if(record.customerInformation.country){
          record['country'] = record.customerInformation.country || '';
        }
        if(record.customerInformation.email){
          record['email'] = record.customerInformation.email || '';
        }
      }
    }); 
    return records.sort(function(a, b){
      if (a.fields[args.property]== undefined) return 1;
      if (b.fields[args.property]== undefined) return -1;

      if(args.property == 'size'){
        if(args.direction ==1){
          return a.fields[args.property] - b.fields[args.property];
        }else{
          return b.fields[args.property] - a.fields[args.property];
        }
      }
      else{
      if(a.fields[args.property] < b.fields[args.property]){
        return -1 * args.direction;
      }
      else if( a.fields[args.property] > b.fields[args.property]){
        return 1 * args.direction;
      }
      else{
        return 0;
      }
    }
  })
}
}