import { Component, OnInit } from '@angular/core';
import {UIService} from './../../_services/ui.service'
@Component({
  selector: 'app-searchform',
  templateUrl: './searchform.component.html',
  styleUrls: ['./searchform.component.scss']
})
export class SearchformComponent implements OnInit {

  constructor(private uiservice:UIService) { }

  ngOnInit() {
  }
  openAdavanceFrom(e){
    this.uiservice.overlay('show');
    let advanceForm = document.querySelector('#navsearch');
    advanceForm.classList.add('active');
  }
}
