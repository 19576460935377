import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTRACSDate'
})
export class FormatTRACSDatePipe extends DatePipe implements PipeTransform {
  constructor() {
    super('en-US');
  }

  transform(value: number, ...args: any[]): any {
    return !value ? 'N/A' : super.transform(value * 1000);
  }

}
