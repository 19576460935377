// PACKAGES
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
// APP
import { InvData } from 'src/app/dashboard/file-explorer-page/file-explorer-page.types';
import { formatBytes } from 'src/app/_utils/utils';
import { Project } from 'src/types/Project';
import { ActiveProjectState } from 'src/app/_state/projects/projects.reducer';
import { FileExplorerStateService, SortDirection, SortMethod, ViewMode } from 'src/app/_state/file-explorer/file-explorer-state.service';

@Component({
  selector: 'app-explorer-contents',
  templateUrl: './explorer-contents.component.html',
  styleUrls: ['./explorer-contents.component.scss']
})
export class ExplorerContentsComponent implements OnInit, OnDestroy {
  @Input() loading: boolean;
  @Input() noData: boolean;
  
  @Input() activeDirectory: InvData;
  @Output() activeDirectoryChange = new EventEmitter();
  @Input() checkedContent: InvData[];
  @Output() checkedContentChange = new EventEmitter();

  selectedDataDetails: InvData['details'];

  sortedContents: InvData[];
  currentSortingMethod: SortMethod;
  currentSortingDirection: SortDirection;
  viewMode: ViewMode;

  showFileMenu: boolean = false;
  fileMenuLocation: { x: number, y: number };

  activeProject: Project;
  
  _fileExplorerStateListener: Subscription;
  _activeProjectStateListener: Subscription;

  constructor(
    private fileExplorerStateService: FileExplorerStateService,
    private store: Store<{ activeProject: ActiveProjectState }>
  ) { }

  ngOnInit() {
    // listen to file explorer state changes in ngrx store
    this._fileExplorerStateListener = this.fileExplorerStateService.explorerState.subscribe(
      ({ selectedContent, activeDirectory, sortMethod, sortDirection, viewMode, infoPanelContent }) => {

        this.viewMode = viewMode
        this.currentSortingMethod = sortMethod;
        this.currentSortingDirection = sortDirection;

        this.selectedDataDetails = infoPanelContent;

        this.activeDirectory = activeDirectory;
        console.log({ AD: this.activeDirectory });
        this.checkedContent = selectedContent;
        
        if(sortMethod === 'NAME' || sortMethod === 'SIZE') {
          this.sortedContents = sortDirection === 'ASC' ? this.sortContents() : this.sortContents().reverse();
        } else {
          // if sorting by date, put files at top of list since directories dont have dates associated with them
          const sorted = this.sortContents();
          this.sortedContents = sortDirection === 'ASC' ?
            sorted.filter(content => content.details.type === 'FILE' || content.details.type === 'BACKUP').concat(sorted.filter(content => content.details.type !== 'FILE' && content.details.type !== 'BACKUP')) :
            sorted.filter(content => content.details.type === 'FILE' || content.details.type === 'BACKUP').reverse().concat(sorted.filter(content => content.details.type !== 'FILE' && content.details.type !== 'BACKUP'));
        }
      }
    );

    // listen to active project state changes in ngrx store
    this._activeProjectStateListener = this.store.select('activeProject').subscribe(({ activeProject }) => {
      this.activeProject = activeProject;
    })
  }

  ngOnDestroy() {
    this._fileExplorerStateListener.unsubscribe();
    this._activeProjectStateListener.unsubscribe();
  }

  /** View content details in the Info Panel side viewer */
  viewContentDetails(content: InvData) {
    this.selectedDataDetails = content.details;
    this.fileExplorerStateService.setInfoPanelContent(this.selectedDataDetails);
  }

  /** select content for actions */
  checkContent(content: InvData, multiselect: boolean) {
    if(multiselect) {
      if(this.checkedContent.includes(content)) {
        this.checkedContent = this.checkedContent.filter(data => data.details.id !== content.details.id);
      } else {
        this.checkedContent = [ ...this.checkedContent, content ];
      }
    } else {
      this.checkedContent = [ content ];
    }

    this.fileExplorerStateService.setSelectedContent(this.checkedContent);
  }

  /** clear all selected content */
  clearCheckedContent() {
    this.fileExplorerStateService.setSelectedContent([]);
  }

  /** set current active directory to specified folder */
  setActiveDirectory(index: number) {
    const folder = this.activeDirectory.contents[index];
    this.fileExplorerStateService.setActiveDirectory(folder);
    this.fileExplorerStateService.toggleFolderExpansion(folder.details.id);
  }

  /** Change sorting method and store in redux store */
  changeSortingMethod(method: SortMethod) {
    // if not changing methods, change direction of sort
    if(this.currentSortingMethod === method) {
      const sortDirection = this.currentSortingDirection === 'ASC' ? 'DESC' : 'ASC';
      this.fileExplorerStateService.setSortDirection(sortDirection);
    }
    // if changing sort methods
    else {
      this.fileExplorerStateService.setSortMethod(method);
      this.fileExplorerStateService.setSortDirection('ASC');
    }
  }
  
  /** sort contents based on sorting method specified by user */
  sortContents(): InvData[] {
    if(!this.activeDirectory || !this.activeDirectory.contentsFetched || this.activeDirectory.contents.length === 0) {
      return []
    }

    switch(this.currentSortingMethod) {
      case 'NAME':
        return this.activeDirectory.contents.sort((a, b) => {
          const nameA = a.details.name.toUpperCase();
          const nameB = b.details.name.toUpperCase();
          if(nameA > nameB) { return 1; }
          if(nameA < nameB) { return -1; }
          return 0;
        });
      
      case 'SIZE':
        return this.activeDirectory.contents.sort((a, b) => {
          const sizeA = 'size' in a.details ? a.details.size : 0;
          const sizeB = 'size' in b.details ? b.details.size : 0;
          if(sizeA > sizeB) return 1;
          else if (sizeA < sizeB) return -1;
          else return 0;
        });
      
      case 'ACCESS_DATE':
        return this.activeDirectory.contents.sort((a, b) => {
          const dateA = 'accessDate' in a.details ? a.details.accessDate : 0;
          const dateB = 'accessDate' in b.details ? b.details.accessDate : 0;
          if(dateA > dateB) return 1;
          else if(dateA < dateB) return -1;
          else return 0;
        });

      case 'MODIFY_DATE':
        return this.activeDirectory.contents.sort((a, b) => {
          const dateA = 'modifyDate' in a.details ? a.details.modifyDate : 0;
          const dateB = 'modifyDate' in b.details ? b.details.modifyDate : 0;
          if(dateA > dateB) return 1;
          else if(dateA < dateB) return -1;
          else return 0;
        });

      case 'CREATED_DATE':
        return this.activeDirectory.contents.sort((a, b) => {
          let dateA;
          let dateB;

          if('createDate' in a.details) dateA = a.details.createDate; // if FileInfo instance
          else if('backupDate' in a.details) dateA = a.details.backupDate; // if BackupSession instance
          else dateA = 0;

          if('createDate' in b.details) dateB = b.details.createDate; // if FileInfo instance
          else if('backupDate' in b.details) dateB = b.details.backupDate; // if BackupSession instance
          else dateB = 0;

          if(dateA > dateB) return 1;
          else if(dateA < dateB) return -1;
          else return 0;
        });
    }
  }

  /** if all are selected, unselect all, otherwise select the rest */
  checkAll() {
    if(this.checkedContent.length === this.activeDirectory.contents.length) {
      this.clearCheckedContent();
      return;
    } else {
      const nonSelectedContents = this.activeDirectory.contents.filter(data => !this.checkedContent.includes(data));
      nonSelectedContents.forEach(data => this.checkContent(data, true));
      return;
    }
  }

  /** format number into size string */
  formatBytes(size: number) {
    return formatBytes(size, 2);
  }

  /** convert a date in seconds to milliseconds */
  secondsToMilliseconds(date: number | undefined) {
    return date * 1000 || undefined;
  }

}
