import { Injectable } from '@angular/core';

type TimerKey = 'TAGGING';

interface Timer {
  timer: any; // nodejs timer
  endTime: number;
}

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  timers: { [key: string]: Timer };

  constructor() { this.timers = {}; }

  /** Start and set timer variable */
  startTimer(timerKey: TimerKey, ms: number) {
    this.timers[timerKey] = {
      endTime: (new Date()).getTime() + ms,
      timer: setTimeout(() => {
        delete this.timers[timerKey];
      }, ms),
    };
  }

  /** stop and delete timer */
  stopTimer(timerKey: TimerKey) {
    clearTimeout(this.timers[timerKey].timer);
    delete this.timers[timerKey];
  }

  /** get time remaining in a timer */
  timeRemaining(timerKey: TimerKey) {
    if(!this.timers[timerKey]) {
      return 0;
    }

    return this.timers[timerKey].endTime - (new Date()).getTime();
  }
}
