import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

export type DialogType = 'DIALOG' | 'LOADING';

@Injectable()
export class DialogService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;

    constructor(private router: Router) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next();
                }
            }
        });
    }
    reloadComponent = new Subject<any>();

    close() {
        this.subject.next({ close: true });
    }

    loading(title: string, message: string) {
        this.subject.next({ title, text: message, type: 'LOADING' });
    }

    show(title:string, message: any) {
        // this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ title: title, text: message, type: 'DIALOG' });
    }

    error(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message });
    }

    unsubscribe(){
        console.log('closed')
    }

    getProps(): Observable<any> {
        return this.subject.asObservable();
    }
}