import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { UIService } from './ui.service';
import { SESSION_TIME } from './../_config/config';
import { JwtHelperService } from "@auth0/angular-jwt";
import { map} from 'rxjs/operators';
import { API_ENDPOINT } from './../_config/config';
import { AuthService } from './auth.service';
import { PartnersService } from './partners.service';
import { CustomerService } from './customer.service';

interface Counts {
    partners?: number;
    customers?: number;
    projects: number;
    users: number;
}

@Injectable({
    providedIn: 'root'
  })
export class CountService {
    token: string;

    counts = new BehaviorSubject<Counts>({
        partners: 0,
        customers: 0,
        projects: 0,
        users: 0,
    });

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private customerService: CustomerService,
        private partnerService: PartnersService,
    ) { }

    /** Get counts depending on the signed in user's role */
    getCounts() {
        if(this.authService.isSuperadmin)    return this.getAdminCounts();
        if(this.authService.isPartnerAdmin)  return this.getPartnerCounts();
        if(this.authService.isCustomerAdmin) return this.getCustomerCounts();
    }

    private getAdminCounts() {
        return this.http.get<{ counts: Counts }>(`${API_ENDPOINT}/counts/admin`).pipe(
            map(resp => {
                const { partners, customers, projects, users } = resp.counts;
                this.counts.next({ partners, customers, projects, users });
                return resp;
            })
        );
    }

    private getPartnerCounts() {
        const partnerId = this.partnerService.partner.id;
        return this.http.get<{ counts: Counts }>(`${API_ENDPOINT}/counts/partner/${partnerId}`).pipe(
            map(resp => {
                const { customers, projects, users } = resp.counts;
                this.counts.next({ partners: null, customers, projects, users });
                return resp;
            })
        )
    }

    private getCustomerCounts() {
        const customerId = this.customerService.customer.id;
        return this.http.get<{ counts: Counts }>(`${API_ENDPOINT}/counts/customer/${customerId}`).pipe(
            map(resp => {
                const { users, projects } = resp.counts;
                this.counts.next({ partners: null, customers: null, projects, users });
                return resp;
            })
        )
    }
}