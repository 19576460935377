import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UIService } from './ui.service';
import { SESSION_TIME } from './../_config/config';
import { JwtHelperService } from "@auth0/angular-jwt";
import { map} from 'rxjs/operators';
import { API_ENDPOINT } from './../_config/config';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { isNumber, padNumber } from '../_utils/utils';

export interface SearchParams {
    fileName?: string;
    extension?: string;
    filePath?: string;
    hash?: string;
    minSize?: number;
    maxSize?: number;
    taggedOnly?: boolean;
    minCreateDate?: NgbDateStruct;
    maxCreateDate?: NgbDateStruct;
    minModifyDate?: NgbDateStruct;
    maxModifyDate?: NgbDateStruct;
    minAccessDate?: NgbDateStruct;
    maxAccessDate?: NgbDateStruct;
}

export interface SearchOptions {
    limit: number;
    pageNumber: number;
    sortAttr: string;
    sortDirection: 'ASC' | 'DESC';
    taggedOnly: boolean;
}

export const defaultSearchOpts: SearchOptions = {
    limit: 100,
    pageNumber: 1,
    sortAttr: 'name',
    sortDirection: 'ASC',
    taggedOnly: false,
}

export interface FileQueryReturnObject {
    files: any[];
    count: number;
    analytics: {
        topExtensions: { extension: string; count: number }[];
        size: { min: number; max: number; };
        modifyDate: { min: number; max: number; }
    }
}

/** Convert NgbDateStruct to number value of date */
function getDateValue(date: NgbDateStruct, addOne: boolean = false) {
    const day = addOne ? date.day + 1 : date.day; // add 1 day so max date does not cut off based on time

    const dateString = `${isNumber(date.month) ? padNumber(date.month) : ''}/${isNumber(day) ? padNumber(day) : ''}/${date.year}`
    return new Date(dateString).valueOf().toString();
}

@Injectable({
    providedIn: 'root'
  })
export class SearchService {
    token:string;
    constructor(private http:HttpClient, private uiservice : UIService, private jwt:JwtHelperService) { }

    private generateQueryParams(query: SearchParams, options: SearchOptions) {
        const offset = options.limit * (options.pageNumber - 1);

        // build query params
        let params = new HttpParams();

        params = params.append('limit', options.limit.toString());
        params = params.append('offset', offset.toString());
        params = params.append('sortDirection', options.sortDirection);
        params = options.sortAttr   ? params.append('sortAttr', options.sortAttr) : params;
        params = options.taggedOnly ? params.append('taggedOnly', 'true') : params;
        console.log(params);

        Object.entries(query).forEach(([ key, value ]) => {
            if(key.includes('Date')) { // convert DateStructs to number value
                query[key] = key.includes('max') ? getDateValue(value, true) : getDateValue(value);
            }
            // add query attr to http query params
            params = params.append(key, query[key]);
        });

        return params;
    }

    /** Get files based on project id */
    getFiles(projectId: string, query: SearchParams, options: SearchOptions = defaultSearchOpts) {
        console.log(query);
        const params = this.generateQueryParams(query, options);
        return this.http.get<FileQueryReturnObject>(`${API_ENDPOINT}/projects/${projectId}/files/search`, { params });
    }

    /** Get exact count of all records matching query */
    getExactRecordCount(projectId: string, query: SearchParams, options: SearchOptions = defaultSearchOpts) {
        const params = this.generateQueryParams(query, options);
        return this.http.get<{ count: number }>(`${API_ENDPOINT}/projects/${projectId}/files/search/count`, { params });
    }

    /** Download specified file records */
    download(projectId: string, fileIds: string[]) {
        return this.http.post<{ table: string[][] }>(`${API_ENDPOINT}/projects/${projectId}/files/download`, { fileIds });
    }

    /** Download all tagged records for a project */
    downloadAllTaggedRecords(projectId: string) {
        let params = new HttpParams().set('projectId', projectId);

        return this.http.get<{ table: string[][] }>(`${API_ENDPOINT}/projects/${projectId}/files/download/tagged`, { params });
    }
    
    /** Tag file records in database */
    tagItems(projectId: string, fileIds: string[]) {
        return this.http.patch<{ tagged: number }>(`${API_ENDPOINT}/projects/${projectId}/files/tag`, { fileIds })
    }

    /** remove tag from specified records in database */
    untagItems(projectId: string, fileIds: string[]) {
        return this.http.patch<{ untagged: number }>(`${API_ENDPOINT}/projects/${projectId}/files/untag`, { fileIds })
    }
}