// PACKAGES
import { createAction, props } from '@ngrx/store';
// APP
import { Project } from '../../../types/Project';

export const setActiveProject = createAction(
    '[PROJECTS] SET_ACTIVE_PROJECT',
    props<{ project: Project }>()
);

export const clearActiveProject = createAction('[PROJECTS] CLEAR_ACTIVE_PROJECT');