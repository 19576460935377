import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrls: ['./backup.component.scss']
})
export class BackupComponent implements OnInit {
  constructor(
    private route : ActivatedRoute,
    private router :  Router) { 
      this.router.events.subscribe((event) => {
        console.log(event);
    });
    }
  backupname:any;

  ngOnInit() {
    this.backupname = this.route.snapshot.paramMap.get('name');
    console.log('called' + this.backupname);
  }

}
