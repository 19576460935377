import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-guidelines',
  templateUrl: './search-guidelines.component.html',
  styleUrls: ['./search-guidelines.component.scss']
})
export class SearchGuidelinesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  preventDefault(e) {
    e.preventDefault();
  }

}
