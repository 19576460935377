import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, AuthService, UIService } from './../_services/index';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthService,
    private uiservice : UIService) { }

  ngOnInit() {
    this.uiservice.setTitle('Logout'); 
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
