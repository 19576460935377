import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { AuthService } from '../../_services/auth.service';
import { UserService } from '../../_services';
import { CountUpdateService } from '../../_services/count-update.service';
import { ActiveProjectState } from '../../_state/projects/projects.reducer';
import { Project } from 'src/types/Project';
import { Role } from 'src/app/_models/role';
import { PartnersService } from 'src/app/_services/partners.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: []
})
export class SidebarComponent implements OnInit, OnDestroy {
  constructor(
    public router: Router,
    public authService:AuthService,
    public partnerService: PartnersService,
    private userService : UserService,
    public countUpdate : CountUpdateService,
    private store: Store<{ activeProject: ActiveProjectState }>,
  ) { }

  activeProject: Project;
  _activeProjectStateListener: Subscription;

  treeview = false;
  isOpen = true;
  activeSubitem : any;
  childItem : any;
  projects : any;
  loadingMenu :any;

  Role = Role;
  
  ngOnInit() {
    // subscribe to NGRX Store changes
    this._activeProjectStateListener = this.store.select('activeProject').subscribe(({ activeProject }) => {
      this.activeProject = activeProject;
    });

    this.childItem = false;
    if(this.authService.isReadOnly){
      this.getProjects();
      this.loadingMenu = true;
    }
  }

  ngOnDestroy() {
    this._activeProjectStateListener.unsubscribe();
  }

  toggleSidebar() { 
    this.isOpen = !this.isOpen;
  }

  openSubitems(event:any, name:string){ 
    if(event.type == 'click' && this.isOpen){
      if(this.activeSubitem === name){
        this.activeSubitem = false;
      }else{
        this.activeSubitem = name;
      }
    }
    if(event.type == 'mouseenter' && !this.isOpen){
      if(this.activeSubitem === name){
        this.activeSubitem = false;
      }else{
        this.activeSubitem = name;
      }
    }
  }

  /** Get user's assigned projects */
  getProjects() {
    this.userService.getAssignedProjects().subscribe(
      ({ projects }) => { 
          this.loadingMenu = false;
          this.projects = projects;
      },
      (error) => {
        alert("Something went wrong");
      }
    );
  }
  
  hideSubmenu(){
    this.activeSubitem = false;
    this.countUpdate.removeBack.next(false);
  }
  toggleTree(id:any){
    const subtree = document.getElementById('subtree'+id); 
    const treeicon = document.getElementById('treeicon'+id); 
    if(subtree.style.display == "" || subtree.style.display == 'none'){
      subtree.style.display = 'block';
      treeicon.classList.remove('fa-plus-circle');
      treeicon.classList.add('fa-minus-circle');
    }else{
      subtree.style.display = 'none';
      treeicon.classList.remove('fa-minus-circle');
      treeicon.classList.add('fa-plus-circle');
    }
  }
  toggleMedia(id:any){
    const tree = document.getElementById('tree'+id); 
    const mbicon = document.getElementById('mbicon'+id); 
    if(tree.style.display == "" || tree.style.display == 'none'){
      tree.style.display = 'block';
      mbicon.classList.remove('fa-chevron-down');
      mbicon.classList.add('fa-chevron-up');
    }else{
      tree.style.display = 'none';
      mbicon.classList.remove('fa-chevron-up');
      mbicon.classList.add('fa-chevron-down');
    }
  }
  toggleChild(name:any, currentProjectName){
    this.countUpdate.currentProject.next(currentProjectName);
    if(this.childItem === name){
      this.childItem = false;
    }else{
      this.childItem = name;
    }
  }
  showTreeview(){
    this.treeview = true;
  }
  backToMenu(){
    this.treeview = false;
  }

  removeBack(){
    this.countUpdate.removeBack.next(false);
  }

  isActiveLink(linkUrl: string, exact: boolean = false): boolean {
    if(exact) {
      const regex = new RegExp(`/${linkUrl}$/`);
      return regex.test(this.router.url);
    }

    return this.router.url.includes(linkUrl);
  }
}
