// PACKAGES
import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// APP
import { InvData } from 'src/app/dashboard/file-explorer-page/file-explorer-page.types';
import { FileExplorerStateService } from 'src/app/_state/file-explorer/file-explorer-state.service';

@Component({
  selector: 'app-explorer-nav-row',
  templateUrl: './explorer-nav-row.component.html',
  styleUrls: ['./explorer-nav-row.component.scss']
})
export class ExplorerNavRowComponent implements OnInit {
  @Input() folder: InvData;
  @Input() depth: number = 0;
  
  activeDirectory: InvData;

  hasChildFolder: boolean;
  expanded: boolean = false;
  active: boolean = false;

  _fileExplorerStateListener: Subscription;

  constructor(private fileExplorerStateService: FileExplorerStateService) { }

  ngOnInit() {
    this.fileExplorerStateService.explorerState.subscribe(({ activeDirectory, expandedNavRowIds }) => {
      // if previously expanded, re-expand
      if(expandedNavRowIds.includes(this.folder.details.id)) {
        this.expanded = true;
      }

      this.activeDirectory = activeDirectory;
      // if the active directory and component folder ids match
      if(this.activeDirectory && this.activeDirectory.details.id === this.folder.details.id) {
        this.folder = this.activeDirectory; // update folder value if activeDirectoryChange comes from anotherComponent
        this.active = true;
      } else {
        this.active = false;
      }

      this.moveOtherFolderToEndOfList();
    })

    // when type BACKUP, MEDIA, or PARENT are passed in, set this.hasChildFolder
    const hasChildFolderTypes = ['BACKUP', 'MEDIA', 'PARENT']
    if(hasChildFolderTypes.includes(this.folder.details.type)) 
      this.hasChildFolder = true;
    else if('hasFolderChild' in this.folder.details)
      this.hasChildFolder = this.folder.details.hasFolderChild;
    else
      this.hasChildFolder = false;
  }

  /** toggle expansion of folder */
  toggleExpansion() {
    if(!this.hasChildFolder)
      return;
    
    this.expanded = !this.expanded;
    this.fileExplorerStateService.toggleFolderExpansion(this.folder.details.id);
  }

  /** set active directory in file explorer state */
  setActiveDirectory() {
    this.fileExplorerStateService.setActiveDirectory(this.folder);
    !this.expanded ? this.fileExplorerStateService.toggleFolderExpansion(this.folder.details.id) : null;
  }

  moveOtherFolderToEndOfList() {
    if(
      this.folder.details.name === 'Media Sources' &&
      this.folder.contents.findIndex(c => c.details.name === 'Others') !== -1
    ) {
      const ind = this.folder.contents.findIndex(c => c.details.name === 'Others');
      const otherFolder = this.folder.contents.splice(ind, 1)[0];
      this.folder.contents.push(otherFolder);
    }
  }

}
