import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UIService, AlertService } from '../_services';
import { AuthService } from '../_services/auth.service'

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit, OnDestroy {
  model: any = {};
  loading : boolean;

  logo: string;
  logoListener: Subscription;
  
  constructor(
    private uiservice:UIService,
    private authService : AuthService,
    private alert: AlertService,
    private formBuilder: FormBuilder
    ) { }
  
  resetForm: FormGroup;
  submitted = false;
  ngOnInit() {
    this.uiservice.setTitle('Forgot Password'); 
    this.model.email = '';

    // listen to logo changes in UI Service
    this.logoListener = this.uiservice.logoSubject.subscribe(logo => this.logo = logo);

    this.formValidate();
  }

  ngOnDestroy() {
    this.logoListener.unsubscribe();
  }

  formValidate(){
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }
  onSubmit() {
    this.submitted = true;
      // stop the process here if form is invalid
      console.log(this.resetForm);
      if (this.resetForm.invalid) {
          return false;
      }
      this.loading = true;
      const email = this.resetForm.value.email;
      this.resetPassword(email);
  }
  
  resetPassword(email: string) {
    this.authService.resetPassword(email).subscribe(
      ({ message }) => {
        this.loading = false;
        this.submitted = false;
        this.resetForm.reset();
        this.alert.success(message);
      },
      ({ error }) => { 
        this.loading = false;
        this.submitted = false;
        error.statusCode && error.statusCode === 400 ?
          this.alert.error(error.message) :
          this.alert.error('Something went wrong! Please try again later');
      });
  }
}
