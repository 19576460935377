import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Role } from '../_models/role';
import { AuthService } from '../_services';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userRole = this.authService.user.role;
    const allowedRole: Role = next.data.role;

    // allow anything if user is superadmin
    if(userRole === Role.SuperAdmin) {
      return true;
    }

    if(allowedRole && this.authService.hasAccess(allowedRole)) {
      return true;
    }
    
    this.router.navigate(['/dashboard']);
    return false;
  }
}