import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

const wsColWidths = [
  { wch: 14 },
  { wch: 14 },
  { wch: 14 },
  { wch: 25 },
  { wch: 67 },
  { wch: 24 },
  { wch: 24 },
  { wch: 24 },
  { wch: 35 },
]

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  generateExcelFile(table: string[][], taggedFiles: boolean = false) {
    const sheetName = 'records';
    const date = new Date().toLocaleString();
    const fileName = taggedFiles ?
      `inv_tagged_records ${date}.xlsx` :
      `inv_records ${date}.xlsx`;

    // generate worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(table);
    ws['!cols'] = wsColWidths;

    // generate workbook and add the worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    XLSX.writeFile(wb, fileName);
  }
}
