// PACKAGES
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Store } from '@ngrx/store';
// APP
import { API_ENDPOINT } from './../_config/config';
import { ActiveProjectState } from '../_state/projects/projects.reducer';
import { clearActiveProject } from '../_state/projects/projects.actions';
import { Role } from '../_models/role';
import { PartnersService } from './partners.service';

@Injectable()
export class AuthService {
    access_token: string = null;

    constructor(
        private http: HttpClient,
        private jwt: JwtHelperService,
        private partnerService: PartnersService,
        private store: Store<{ activeProject: ActiveProjectState }>
    ) { }

    get user() { return this.jwt.decodeToken(this.access_token); }

    get userId(): string { return this.user.userId; };
    get userEmail(): string { return this.user.email; }

    get isSuperadmin(): boolean { return this.user ? this.user.role === Role.SuperAdmin : undefined }
    get isPartnerAdmin(): boolean { return this.user.role === Role.PartnerAdmin }
    get isCustomerAdmin(): boolean { return this.user.role === Role.CustomerAdmin }
    get isReadOnly(): boolean { return this.user.role === Role.ReadOnly; }

    public get accessToken(): string { return this.access_token; }
    public get loggedIn(): boolean { return !!this.access_token; }

    /** Send login request */
    login(email: string, password: string) {
        const body = { email, password };
        const partnerId = this.partnerService.partner.id;
        return this.http.post<{ access_token: string }>(`${API_ENDPOINT}/auth/login/${partnerId}`, body, { withCredentials: true });
    }

    /** Logout and revoke refresh token cookie */
    public logout() {
        this.access_token = null;
        this.store.dispatch(clearActiveProject());
        console.log('running');
        return this.http.get(`${API_ENDPOINT}/auth/logout`, {
            withCredentials: true,
        });
    }

    /** Attempt to get a new Access token if user client has Refresh Token */
    public refreshAccessToken() {
        return this.http.get<{ access_token: string }>(`${API_ENDPOINT}/auth/refresh-token`, {
            withCredentials: true,
        });
    }

    /** Set access token */
    public setAccessToken(access_token: string) {
        this.access_token = access_token;
    }

    // return true of false depending on the role tier
    hasAccess(role: Role | keyof typeof Role) {
        switch(role) {
            case Role.SuperAdmin:
            case "SuperAdmin":
                return this.isSuperadmin;
            case Role.PartnerAdmin:
            case "PartnerAdmin":
                return this.isSuperadmin || this.isPartnerAdmin;
            case Role.CustomerAdmin:
            case "CustomerAdmin":
                return this.isSuperadmin || this.isPartnerAdmin || this.isCustomerAdmin;
            case Role.ReadOnly:
            case "ReadOnly":
                return this.isSuperadmin || this.isPartnerAdmin || this.isCustomerAdmin || this.isReadOnly;
            default:
                return false;
        }
    }

    /** Send reset password request to API */
    resetPassword(email: string) {
        return this.http.patch<{ statusCode: number, message: string }>(`${API_ENDPOINT}/auth/reset-password`, { email })
    }
}