// PACKAGES
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BackupSessionInfo, FileInfo, InvData, MediaInfo, PathInfo } from '../dashboard/file-explorer-page/file-explorer-page.types';
// APP
import { API_ENDPOINT } from '../_config/config';

interface FileExplorerSubjectState {
  activeDirectory: InvData;
  selectedContent: InvData[];
}

@Injectable({
  providedIn: 'root'
})
export class FileExplorerService {
  state = new BehaviorSubject<FileExplorerSubjectState>({ activeDirectory: null, selectedContent: [] });
  
  constructor(private http: HttpClient) { }

  /** Get Project Media and Backup Sessions */
  getProjectMedia(projectId: string) {
    return this.http.get<{
      count: { media: number, backupSessions: number }, 
      media: MediaInfo[],
      backupSessions: BackupSessionInfo[] 
    }>(`${API_ENDPOINT}/projects/${projectId}/media`);
  }

  /** Get Projects root treeview folders */
  getRootFolders(projectId: string, backupSessionId: string) {
    let params = new HttpParams();
    params = params.append('backupSessionId', encodeURIComponent(backupSessionId));

    return this.http.get<{ count: number, directories: PathInfo[] }>(`${API_ENDPOINT}/projects/${projectId}/directories/roots`, { params });
  };

  /** Get contents of a folder */
  getFolderContents(projectId: string, directoryId: string) {
    return this.http.get<{ count: number, parentDirectoryId: string, contents: (FileInfo | PathInfo)[] }>
    (`${API_ENDPOINT}/projects/${projectId}/directories/${encodeURIComponent(directoryId)}/contents`);
  }
  
  setActiveDirectory(directory: InvData) {
    this.state.next({
      activeDirectory: directory,
      selectedContent: [],
    });
  }

  get explorerState() {
    return this.state.asObservable();
  }

}
